<template>
  <div class="tdList">
    <my-header>工作待办</my-header>
    <!-- Tab 标签栏---------------------------------------
          1. v-model="busiType" 绑定一个值, 指定当前激活的tab栏的下标, 下标0开始
          2. sticky 吸顶粘性布局
          3. animated 切换动画
          4. swipeable 滑动切换效果

          下拉刷新
          v-model 设置为 false，表示加载完成
     ----------------------------------------------------------->
    <div class="state-tabs">
      <van-tabs
        v-model="busiType"
        @click="handleClick"
        sticky
        animated
        swipeable
      >
        <!-- 待办任务 -->
        <van-tab title="全部" name="ALL">
          <van-checkbox-group v-model="checked" @change="handleCheck">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">

           <div
              class="wait wait1"
              v-for="item in listinfo.paybill"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('paybill', item.billId)" style="marginTop:10px;">
                <h4>付款单</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                  <span class="fl">单据号</span>
                  <em class="num fr">{{ item.billNo }}</em>
                </div>
                <!-- 单据日期 -->
                <div class="row clearfix">
                  <span class="fl">单据日期</span>
                  <em class="startTime fr">{{ item.billDate }}</em>
                </div>
                <!-- 组织 -->
                <div class="row clearfix">
                  <span class="fl">组织</span>
                  <em class="startTime fr">{{ item.orgName }}</em>
                </div>
                <!-- 部门 -->
                <div class="row clearfix">
                  <span class="fl">部门</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 供应商  -->
                <div class="row clearfix">
                  <span class="fl">供应商</span>
                  <em class="place fr">{{ item.supplier }}</em>
                </div>
                <!-- 收支部门  -->
                <div class="row clearfix">
                  <span class="fl">收支部门</span>
                  <em class="place fr">{{ item.szDeptName }}</em>
                </div>
                <!-- 制单人 -->
                <div class="row clearfix">
                  <span class="fl">制单人</span>
                  <em class="place fr">{{ item.creator }}</em>
                </div>
                <!-- 总金额 -->
                <div class="row clearfix">
                  <span class="fl">总金额</span>
                  <em class="startTime fr">{{ item.totalMoney }}</em>
                </div>
                <!-- 摘要 -->
                <div class="row clearfix">
                  <span class="fl">摘要</span>
                  <em class="startTime fr">{{ item.scomment }}</em>
                </div>
              </div>
            </van-checkbox>
            </div>

           <div
              class="wait wait1"
              v-for="item in listinfo.receipt"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('receipt', item.billId)" style="marginTop:10px;">
                <h4>收款单</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                  <span class="fl">单据号</span>
                  <em class="num fr">{{ item.billNo }}</em>
                </div>
                <!-- 单据日期 -->
                <div class="row clearfix">
                  <span class="fl">单据日期</span>
                  <em class="startTime fr">{{ item.billDate }}</em>
                </div>
                <!-- 组织 -->
                <div class="row clearfix">
                  <span class="fl">组织</span>
                  <em class="startTime fr">{{ item.orgName }}</em>
                </div>
                <!-- 部门 -->
                <div class="row clearfix">
                  <span class="fl">部门</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 客户  -->
                <div class="row clearfix">
                  <span class="fl">客户</span>
                  <em class="place fr">{{ item.customer }}</em>
                </div>
                <!-- 收支部门  -->
                <div class="row clearfix">
                  <span class="fl">收支部门</span>
                  <em class="place fr">{{ item.szDeptName }}</em>
                </div>
                <!-- 制单人 -->
                <div class="row clearfix">
                  <span class="fl">制单人</span>
                  <em class="place fr">{{ item.creator }}</em>
                </div>
                <!-- 总金额 -->
                <div class="row clearfix">
                  <span class="fl">总金额</span>
                  <em class="startTime fr">{{ item.totalMoney }}</em>
                </div>
                <!-- 摘要 -->
                <div class="row clearfix">
                  <span class="fl">摘要</span>
                  <em class="startTime fr">{{ item.scomment }}</em>
                </div>
              </div>
            </van-checkbox>
            </div>

           <div
              class="wait wait1"
              v-for="item in listinfo.expense"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title"  @click="auditDetail('expense', item.billId)" style="marginTop:10px;">
                <h4>费用报销</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                  <span class="fl">单据号</span>
                  <em class="num fr">{{ item.billNo }}</em>
                </div>
                <!-- 单据日期 -->
                <div class="row clearfix">
                  <span class="fl">单据日期</span>
                  <em class="startTime fr">{{ item.billDate }}</em>
                </div>
                <!-- 报销类型 -->
                <div class="row clearfix">
                  <span class="fl">报销类型</span>
                  <em class="startTime fr">{{ item.exType }}</em>
                </div>
                <!-- 报销人单位 -->
                <div class="row clearfix">
                  <span class="fl">报销人单位</span>
                  <em class="startTime fr">{{ item.orgName }}</em>
                </div>
                <!-- 报销人部门  -->
                <div class="row clearfix">
                  <span class="fl">报销人部门 </span>
                  <em class="place fr">{{ item.deptName }}</em>
                </div>
                <!-- 报销人 -->
                <div class="row clearfix">
                  <span class="fl">报销人</span>
                  <em class="place fr">{{ item.exPsn }}</em>
                </div>
                <!-- 报销事由 -->
                <div class="row clearfix">
                  <span class="fl">报销事由</span>
                  <em class="startTime fr">{{ item.scomment }}</em>
                </div>
                <!-- 总金额 -->
                <div class="row clearfix">
                  <span class="fl">总金额</span>
                  <em class="startTime fr">{{ item.totalMoney }}</em>
                </div>
              </div>
            </van-checkbox>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.leave"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('leave', item.billId)" style="marginTop:10px;">
                <h4>休假申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="place fr">{{ item.billMaker }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 休假类别  -->
                <div class="row clearfix">
                  <span class="fl">休假类别 </span>
                  <em class="startTime fr">{{ item.leaveType }}</em>
                </div>
                <!-- 请假时长 -->
                <div class="row clearfix ">
                  <span class="fl">休假总时长</span>
                  <em class="startTime fr">{{ item.sumHour }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span><br>
                  <em class="fl"
                    >部门：{{ item.psnData.deptName }} 姓名：{{ item.psnData.psnName }} 岗位：{{ item.psnData.postName }}</em
                  >
                </div>
              </div>
            </van-checkbox>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.leaveoff"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('leaveoff', item.billId)" style="marginTop:10px;">
                <h4>销假申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 报销人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="place fr">{{ item.billMaker }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 休假类别 -->
                <div class="row clearfix">
                  <span class="fl">休假类别</span>
                  <em class="startTime fr">{{ item.leaveType }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr"
                    >部门：{{ item.psnData.deptName }} 公司：{{
                      item.psnData.orgName
                    }}
                    岗位：{{ item.psnData.postName }} 员工号：{{
                      item.psnData.psnCode
                    }}
                    姓名：{{ item.psnData.psnName }}</em
                  >
                </div>
                <!-- 差异时长 -->
                <div class="row clearfix">
                  <span class="fl">差异时长</span>
                  <em class="startTime fr">{{ item.differencehour }}</em>
                </div>
              </div>
            </van-checkbox>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.overtime"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title"  @click="auditDetail('overtime', item.billId)" style="marginTop:10px;">
                <h4>加班申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="place fr">{{ item.billMaker }}</em>
                </div>
                <!-- 单据日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 报销类型 -->
                <div class="row clearfix">
                  <span class="fl">加班类别</span>
                  <em class="startTime fr">{{ item.overtimeType }}</em>
                </div>
                <!-- 加班总时长 -->
                <div class="row clearfix">
                  <span class="fl">加班总时长</span>
                  <em class="startTime fr">{{ item.sumHour }}</em>
                </div>
                <!-- 是否转调休 -->
                <div class="row clearfix">
                  <span class="fl">是否转调休</span>
                  <em class="startTime fr">{{ item.istorest }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fl"
                    >部门：{{ item.psnData.deptName }} 姓名：{{ item.psnData.psnName }} 岗位：{{ item.psnData.postName }}</em
                  >
                </div>
              </div>
            </van-checkbox>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.away"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title"  @click="auditDetail('away', item.billId)" style="marginTop:10px;">
                <h4>出差申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 出差类别 -->
                <div class="row clearfix">
                  <span class="fl">出差类别</span>
                  <em class="place fr">{{ item.awayType }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr"
                    >部门：{{ item.psnData.deptName }} 公司：{{
                      item.psnData.orgName
                    }}
                    岗位：{{ item.psnData.postName }} 员工号：{{
                      item.psnData.psnCode
                    }}
                    姓名：{{ item.psnData.psnName }}</em
                  >
                </div>
                <!-- 出差总时长 -->
                <div class="row clearfix">
                  <span class="fl">出差总时长</span>
                  <em class="startTime fr">{{ item.sumHour }}</em>
                </div>
              </div>
            </van-checkbox>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.changeshift"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('changeshift', item.billId)" style="marginTop:10px;">
                <h4>调班申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr"
                    >部门：{{ item.psnData.deptName }} 公司：{{
                      item.psnData.orgName
                    }}
                    岗位：{{ item.psnData.postName }} 员工号：{{
                      item.psnData.psnCode
                    }}
                    姓名：{{ item.psnData.psnName }}</em
                  >
                </div>
              </div>
            </van-checkbox>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.payroll"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('payroll', item.billId)" style="marginTop:10px;">
                <h4>薪资发放</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 薪资方案 -->
                <div class="row clearfix">
                  <span class="fl">薪资方案</span>
                  <em class="startTime fr">{{ item.waClass }}</em>
                </div>
                <!-- 薪资期间 -->
                <div class="row clearfix">
                  <span class="fl">薪资期间</span>
                  <em class="startTime fr">{{ item.cperiod }}</em>
                </div>
                <!-- 应发合计 -->
                <div class="row clearfix">
                  <span class="fl">应发合计</span>
                  <em class="startTime fr">{{ item.yfMoney }}</em>
                </div>
                <!-- 实发合计 -->
                <div class="row clearfix">
                  <span class="fl">实发合计</span>
                  <em class="startTime fr">{{ item.sfMoney }}</em>
                </div>
              </div>
            </van-checkbox>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.trans"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('trans', item.billId)" style="marginTop:10px;">
                <h4>调配申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr">
                    员工号：{{item.psnData.psnCode}}
                    姓名：{{ item.psnData.psnName }}</em>
                </div>
                <!-- 调配信息 -->
                <div class="row clearfix person">
                  <span class="fl">调配信息</span>
                  <em class="fr">
                    调配方式：{{ item.psnData.stapplyMode }} 调配业务类型：{{ item.psnData.trnsType }} 是否试用：{{ item.psnData.trialFlag }}<br>
                    调配原因：{{ item.psnData.sreason }} <br>
                    生效日期：{{ item.psnData.effectDate }}   岗位试用期限：{{ item.psnData.trialDays }} <br>
                    试用期限：{{ item.psnData.trialBeginDate }} 至 {{ item.psnData.trialEndDate }}<br>
                    调配说明：{{ item.psnData.memo }}
                    </em>
                </div>
              </div>
            </van-checkbox>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.dimission"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('dimission', item.billId)" style="marginTop:10px;">
                <h4>离职申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr">
                    员工号：{{item.psnData.psnCode}}
                    姓名：{{ item.psnData.psnName }}</em>
                </div>
                <!-- 离职信息 -->
                <div class="row clearfix person">
                  <span class="fl">离职信息</span>
                  <em class="fr">
                    离职业务类型：{{ item.psnData.trnsType }} 生效日期：{{ item.psnData.effectDate }}<br>
                    离职原因：{{ item.psnData.sreason }} <br>
                    离职说明：{{ item.psnData.memo }}
                    </em>
                </div>
              </div>
            </van-checkbox>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.entry"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('entry', item.billId)" style="marginTop:10px;">
                <h4>入职申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr">
                    员工号：{{item.psnData.psnCode}}
                    姓名：{{item.psnData.psnName}}<br>
                    组织：{{item.psnData.orgName}}
                    部门：{{ item.psnData.deptName }}<br>
                    岗位：{{ item.psnData.postName }}
                    人员类别：{{ item.psnData.psnclName }}<br>
                    入职类型：{{ item.psnData.trnsType }}
                    生效日期：{{ item.psnData.effectDate }}<br>
                    身份证：{{ item.psnData.idCard }}
                    手机：{{ item.psnData.mobile }}
                    </em>
                </div>
              </div>
            </van-checkbox>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.regular"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('regular', item.billId)" style="marginTop:10px;">
                <h4>转正申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr">
                    员工号：{{item.psnData.psnCode}}
                    姓名：{{ item.psnData.psnName }}</em>
                </div>
                <!-- 转正信息 -->
                <div class="row clearfix person">
                  <span class="fl">转正信息</span>
                  <em class="fr">
                    试用类型：{{ item.psnData.probationType }} 生效日期：{{ item.psnData.regularDate }}<br>
                    试用开始日期：{{ item.psnData.beginDate }} 结束日期：{{ item.psnData.endDate }} <br>
                    试用结果：{{ item.psnData.trialResult }}
                    </em>
                </div>
              </div>
            </van-checkbox>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.signin"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('signin', item.billId)" style="marginTop:10px;">
                <h4>登记申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 客户名称 -->
                <div class="row clearfix">
                  <span class="fl">客户名称</span>
                  <em class="startTime fr">{{ item.custName }}</em>
                </div>
                <!-- 微信名 -->
                <div class="row clearfix">
                  <span class="fl">微信名</span>
                  <em class="startTime fr">{{ item.nickname }}</em>
                </div>
                <!-- 联系人 -->
                <div class="row clearfix">
                  <span class="fl">联系人</span>
                  <em class="startTime fr">{{ item.name }}</em>
                </div>
                <!-- 电话 -->
                <div class="row clearfix">
                  <span class="fl">电话</span>
                  <em class="startTime fr">{{ item.phone }}</em>
                </div>
                <!-- 联系地址 -->
                <div class="row clearfix">
                  <span class="fl">联系地址</span>
                  <em class="startTime fr">{{ item.address }}</em>
                </div>
                <!-- 应用权限 -->
                <!-- <div class="row clearfix person">
                  <span class="fl">应用权限</span>
                  <em class="startTime fr" v-if = "item.roleRequire">客户建议</em>
                  <em class="startTime fr" v-if = "item.roleComsug">客户投诉</em>
                  <em class="startTime fr" v-if = "item.roleRepair">报事报修</em>
                </div> -->
              </div>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.require"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('require', item.billId)" style="marginTop:10px;">
                <h4>客户建议</h4>
                <span class="waitState">{{item.crmStatusName}}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                  <!-- custName -->
                  <div class="row clearfix">
                    <span class="fl">单位名称</span>
                    <em class="startTime fr">{{ item.custName }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 建议内容 -->
                <div class="row clearfix">
                  <span class="fl">建议内容</span>
                  <em class="startTime fr">{{ item.problemDesc }}</em>
                </div>
                 <!-- 联系人 -->
                 <div class="row clearfix">
                  <span class="fl">联系人</span>
                  <em class="startTime fr">{{ item.name }}</em>
                </div>
                 <!-- 电话 -->
                <div class="row clearfix">
                  <span class="fl">电话</span>
                  <em class="startTime fr">{{ item.phone }}</em>
                </div>
              </div>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.comsug"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('comsug', item.billId)" style="marginTop:10px;">
                <h4>客户投诉</h4>
                <span class="waitState">{{item.crmStatusName}}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                  <!-- custName -->
                  <div class="row clearfix">
                    <span class="fl">单位名称</span>
                    <em class="startTime fr">{{ item.custName }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 投诉内容 -->
                <div class="row clearfix">
                  <span class="fl">投诉内容</span>
                  <em class="startTime fr">{{ item.problemDesc }}</em>
                </div>
                 <!-- 联系人 -->
                 <div class="row clearfix">
                  <span class="fl">联系人</span>
                  <em class="startTime fr">{{ item.name }}</em>
                </div>
                 <!-- 电话 -->
                <div class="row clearfix">
                  <span class="fl">电话</span>
                  <em class="startTime fr">{{ item.phone }}</em>
                </div>
              </div>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.repair"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('repair', item.billId)" style="marginTop:10px;">
                <h4>报事报修</h4>
                <span class="waitState">{{item.crmStatusName}}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 维修部位 -->
                <div class="row clearfix">
                  <span class="fl">维修部位</span>
                  <em class="startTime fr">{{ item.repairPlace }}</em>
                </div>
                <!-- 维修内容 -->
                <div class="row clearfix">
                  <span class="fl">维修内容</span>
                  <em class="startTime fr">{{ item.repairNote }}</em>
                </div>
                <!-- 维修联系人 -->
                <div class="row clearfix">
                  <span class="fl">维修联系人</span>
                  <em class="startTime fr">{{ item.repairPsn }}</em>
                </div>
                <!-- 维修联系电话 -->
                <div class="row clearfix">
                  <span class="fl">维修联系电话</span>
                  <em class="startTime fr">{{ item.repairPhone }}</em>
                </div>
                 <!-- 派单人 -->
                 <div class="row clearfix">
                  <span class="fl">派单人</span>
                  <em class="startTime fr">{{ item.processorUser }}</em>
                </div>
                 <!-- 派单时间 -->
                <div class="row clearfix">
                  <span class="fl">派单时间</span>
                  <em class="startTime fr">{{ item.processorTime }}</em>
                </div>
                <!-- 派单说明 -->
                <div class="row clearfix">
                  <span class="fl">派单说明</span>
                  <em class="startTime fr">{{ item.processorResult }}</em>
                </div>
                <!-- 回访人 -->
                <div class="row clearfix">
                  <span class="fl">回访人</span>
                  <em class="startTime fr">{{ item.revisitUser }}</em>
                </div>
                 <!-- 回访时间 -->
                <div class="row clearfix">
                  <span class="fl">回访时间</span>
                  <em class="startTime fr">{{ item.revisitTime }}</em>
                </div>
                <!-- 回访说明 -->
                <div class="row clearfix">
                  <span class="fl">回访说明</span>
                  <em class="startTime fr">{{ item.revisitMemo }}</em>
                </div>
              </div>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.infochange"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('infochange', item.billId)" style="marginTop:10px;">
                <h4>信息变更</h4>
                <span class="waitState">详情</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 变更内容 -->
                <div class="row clearfix">
                  <span class="fl">变更内容</span>
                  <em class="startTime fr">{{ item.changeInfo }}</em>
                </div>
              </div>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.emprequire"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('emprequire', item.billId)" style="marginTop:10px;">
                <h4>员工建议</h4>
                <span class="waitState">{{ item.crmStatusName }}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                <!-- 建议内容 -->
                <div class="row clearfix">
                  <span class="fl">建议内容</span>
                  <em class="startTime fr">{{ item.problemDesc }}</em>
                </div>
                 <!-- 处理人 -->
                 <div class="row clearfix">
                  <span class="fl">处理人</span>
                  <em class="startTime fr">{{ item.processorUser }}</em>
                </div>
                 <!-- 处置时间 -->
                <div class="row clearfix">
                  <span class="fl">处置时间</span>
                  <em class="startTime fr">{{ item.processorTime }}</em>
                </div>
                <!-- 处置结果 -->
                <div class="row clearfix">
                  <span class="fl">处置结果</span>
                  <em class="startTime fr">{{ item.processorResult }}</em>
                </div>
              </div>
            </div>

          </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

        <van-tab title="付款" name="paybill" v-if="isAuth('paybill')">
          <van-checkbox-group v-model="checked" @change="handleCheck">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.paybill"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('paybill', item.billId)" style="marginTop:10px;">
                <h4>付款单</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                  <span class="fl">单据号</span>
                  <em class="num fr">{{ item.billNo }}</em>
                </div>
                <!-- 单据日期 -->
                <div class="row clearfix">
                  <span class="fl">单据日期</span>
                  <em class="startTime fr">{{ item.billDate }}</em>
                </div>
                <!-- 组织 -->
                <div class="row clearfix">
                  <span class="fl">组织</span>
                  <em class="startTime fr">{{ item.orgName }}</em>
                </div>
                <!-- 部门 -->
                <div class="row clearfix">
                  <span class="fl">部门</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 供应商  -->
                <div class="row clearfix">
                  <span class="fl">供应商</span>
                  <em class="place fr">{{ item.supplier }}</em>
                </div>
                <!-- 收支部门  -->
                <div class="row clearfix">
                  <span class="fl">收支部门</span>
                  <em class="place fr">{{ item.szDeptName }}</em>
                </div>
                <!-- 制单人 -->
                <div class="row clearfix">
                  <span class="fl">制单人</span>
                  <em class="place fr">{{ item.creator }}</em>
                </div>
                <!-- 总金额 -->
                <div class="row clearfix">
                  <span class="fl">总金额</span>
                  <em class="startTime fr">{{ item.totalMoney }}</em>
                </div>
               <!-- 摘要 -->
                <div class="row clearfix">
                  <span class="fl">摘要</span>
                  <em class="startTime fr">{{ item.scomment }}</em>
                </div>

              </div>
            </van-checkbox>
            </div>
          </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

        <van-tab title="收款" name="receipt" v-if="isAuth('receipt')">
          <van-checkbox-group v-model="checked" @change="handleCheck">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.receipt"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('receipt', item.billId)" style="marginTop:10px;">
                <h4>收款单</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                  <span class="fl">单据号</span>
                  <em class="num fr">{{ item.billNo }}</em>
                </div>
                <!-- 单据日期 -->
                <div class="row clearfix">
                  <span class="fl">单据日期</span>
                  <em class="startTime fr">{{ item.billDate }}</em>
                </div>
                <!-- 组织 -->
                <div class="row clearfix">
                  <span class="fl">组织</span>
                  <em class="startTime fr">{{ item.orgName }}</em>
                </div>
                <!-- 部门 -->
                <div class="row clearfix">
                  <span class="fl">部门</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 客户  -->
                <div class="row clearfix">
                  <span class="fl">客户</span>
                  <em class="place fr">{{ item.customer }}</em>
                </div>
                <!-- 收支部门  -->
                <div class="row clearfix">
                  <span class="fl">收支部门</span>
                  <em class="place fr">{{ item.szDeptName }}</em>
                </div>
                <!-- 制单人 -->
                <div class="row clearfix">
                  <span class="fl">制单人</span>
                  <em class="place fr">{{ item.creator }}</em>
                </div>
                <!-- 总金额 -->
                <div class="row clearfix">
                  <span class="fl">总金额</span>
                  <em class="startTime fr">{{ item.totalMoney }}</em>
                </div>
               <!-- 摘要 -->
                <div class="row clearfix">
                  <span class="fl">摘要</span>
                  <em class="startTime fr">{{ item.scomment }}</em>
                </div>

              </div>
            </van-checkbox>
            </div>
          </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

        <van-tab title="报销" name="expense" v-if="isAuth('expense')">
          <van-checkbox-group v-model="checked" @change="handleCheck">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <!-- 一条数据  开始-->
               <div
               class="wait wait1"
               v-for="item in listinfo.expense"
               :key="item.billId">
               <!-- 一条数 据开始 -->
                 <van-checkbox class="item"  :name="item.billId" :key="item.billId">
                  <!-- 卡片内容 -->
                  <div class="items">
                    <div class="title" @click="auditDetail('expense', item.billId)" style="marginTop:10px;">
                      <h4>费用报销</h4>
                      <span class="waitState">待审批</span>
                    </div>
                    <!-- 详情 -->
                    <div class="details">
                      <!-- 订单号 -->
                      <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billNo }}</em>
                      </div>
                      <!-- 单据日期 -->
                      <div class="row clearfix">
                        <span class="fl">单据日期</span>
                        <em class="startTime fr">{{ item.billDate }}</em>
                      </div>
                      <!-- 报销类型 -->
                      <div class="row clearfix">
                        <span class="fl">报销类型</span>
                        <em class="startTime fr">{{ item.exType }}</em>
                      </div>
                      <!-- 报销人单位 -->
                      <div class="row clearfix">
                        <span class="fl">报销人单位</span>
                        <em class="startTime fr">{{ item.orgName }}</em>
                      </div>
                      <!-- 报销人部门  -->
                      <div class="row clearfix">
                        <span class="fl">报销人部门 </span>
                        <em class="place fr">{{ item.deptName }}</em>
                      </div>
                      <!-- 报销人 -->
                      <div class="row clearfix">
                        <span class="fl">报销人</span>
                        <em class="place fr">{{ item.exPsn }}</em>
                      </div>
                      <!-- 报销事由 -->
                      <div class="row clearfix">
                        <span class="fl">报销事由</span>
                        <em class="startTime fr">{{ item.scomment }}</em>
                      </div>
                      <!-- 总金额 -->
                      <div class="row clearfix">
                        <span class="fl">总金额</span>
                        <em class="startTime fr">{{ item.totalMoney }}</em>
                      </div>
                    </div>
                  </div>
                 </van-checkbox>
                </div>
            <!-- 一条数据  结束-->
            </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

         <van-tab title="薪资" name="payroll" v-if="isAuth('payroll')">
          <van-checkbox-group v-model="checked" @change="handleCheck">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.payroll"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('payroll', item.billId)" style="marginTop:10px;">
                <h4>薪资发放</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 薪资方案 -->
                <div class="row clearfix">
                  <span class="fl">薪资方案</span>
                  <em class="startTime fr">{{ item.waClass }}</em>
                </div>
                <!-- 薪资期间 -->
                <div class="row clearfix">
                  <span class="fl">薪资期间</span>
                  <em class="startTime fr">{{ item.cperiod }}</em>
                </div>
                <!-- 应发合计 -->
                <div class="row clearfix">
                  <span class="fl">应发合计</span>
                  <em class="startTime fr">{{ item.yfMoney }}</em>
                </div>
                <!-- 实发合计 -->
                <div class="row clearfix">
                  <span class="fl">实发合计</span>
                  <em class="startTime fr">{{ item.sfMoney }}</em>
                </div>
              </div>
            </van-checkbox>
            </div>
          </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

        <van-tab title="加班" name="overtime" v-if="isAuth('overtime')">
          <van-checkbox-group v-model="checked" @change="handleCheck">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.overtime"
              :key="item.billId"
            >
              <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('overtime', item.billId)" style="marginTop:10px;">
                <h4>加班申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="place fr">{{ item.billMaker }}</em>
                </div>
                <!-- 单据日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 报销类型 -->
                <div class="row clearfix">
                  <span class="fl">加班类别</span>
                  <em class="startTime fr">{{ item.overtimeType }}</em>
                </div>
                <!-- 加班总时长 -->
                <div class="row clearfix">
                  <span class="fl">加班总时长</span>
                  <em class="startTime fr">{{ item.sumHour }}</em>
                </div>
                <!-- 是否转调休 -->
                <div class="row clearfix">
                  <span class="fl">是否转调休</span>
                  <em class="startTime fr">{{ item.istorest }}</em>
                </div>

                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fl"
                    >部门：{{ item.psnData.deptName }} 姓名：{{ item.psnData.psnName }} 岗位：{{ item.psnData.postName }}</em
                  >
                </div>

              </div>
              </van-checkbox>
            </div>
          </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

        <van-tab title="休假" name="leave" v-if="isAuth('leave')">
          <van-checkbox-group v-model="checked" @change="handleCheck">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.leave"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('leave', item.billId)" style="marginTop:10px;">
                <h4>休假申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="place fr">{{ item.billMaker }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 休假类别  -->
                <div class="row clearfix">
                  <span class="fl">休假类别 </span>
                  <em class="scheduledTime fr">{{ item.leaveType }}</em>
                </div>
                <!-- 请假时长 -->
                <div class="row clearfix">
                  <span class="fl">休假总时长</span>
                  <em class="fr">{{ item.sumHour }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span><br>
                  <em class="fl">
                    部门：{{ item.psnData.deptName }} 姓名：{{ item.psnData.psnName }} 岗位：{{ item.psnData.postName }}
                  </em>
                </div>
              </div>
            </van-checkbox>
            </div>
          </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

        <van-tab title="销假" name="leaveoff" v-if="isAuth('leaveoff')">
          <van-checkbox-group v-model="checked" @change="handleCheck">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.leaveoff"
              :key="item.billId"
            >
            <van-checkbox :name="item.billId" v-model="checked" @click="auditDetail('leaveoff', item.billId)" style="marginTop:10px;">
              <div class="title">
                <h4>销假申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 报销人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="place fr">{{ item.billMaker }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 休假类别 -->
                <div class="row clearfix">
                  <span class="fl">休假类别</span>
                  <em class="startTime fr">{{ item.leaveType }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr"
                    >部门：{{ item.psnData.deptName }} 公司：{{
                      item.psnData.orgName
                    }}
                    岗位：{{ item.psnData.postName }} 员工号：{{
                      item.psnData.psnCode
                    }}
                    姓名：{{ item.psnData.psnName }}</em
                  >
                </div>
                <!-- 差异时长 -->
                <div class="row clearfix">
                  <span class="fl">差异时长</span>
                  <em class="startTime fr">{{ item.differencehour }}</em>
                </div>
              </div>
            </van-checkbox>
            </div>
          </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

        <van-tab title="出差" name="away" v-if="isAuth('away')">
           <van-checkbox-group v-model="checked" @change="handleCheck">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.away"
              :key="item.billId"
            >
              <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('away', item.billId)" style="marginTop:10px;">
                <h4>出差申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 出差类别 -->
                <div class="row clearfix">
                  <span class="fl">出差类别</span>
                  <em class="place fr">{{ item.awayType }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr"
                    >部门：{{ item.psnData.deptName }} 公司：{{
                      item.psnData.orgName
                    }}
                    岗位：{{ item.psnData.postName }} 员工号：{{
                      item.psnData.psnCode
                    }}
                    姓名：{{ item.psnData.psnName }}</em
                  >
                </div>
                <!-- 出差总时长 -->
                <div class="row clearfix">
                  <span class="fl">出差总时长</span>
                  <em class="startTime fr">{{ item.sumHour }}</em>
                </div>
              </div>
              </van-checkbox>
            </div>
          </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

        <van-tab title="调班" name="changeshift" v-if="isAuth('changeshift')">
          <van-checkbox-group v-model="checked" @change="handleCheck">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.changeshift"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('changeshift', item.billId)" style="marginTop:10px;">
                <h4>调班申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr"
                    >部门：{{ item.psnData.deptName }} 公司：{{
                      item.psnData.orgName
                    }}
                    岗位：{{ item.psnData.postName }} 员工号：{{
                      item.psnData.psnCode
                    }}
                    姓名：{{ item.psnData.psnName }}</em
                  >
                </div>
              </div>
            </van-checkbox>
            </div>
          </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

        <van-tab title="调配" name="trans" v-if="isAuth('trans')">
          <van-checkbox-group v-model="checked" @change="handleCheck">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.trans"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('trans', item.billId)" style="marginTop:10px;">
                <h4>调配申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr">
                    员工号：{{item.psnData.psnCode}}
                    姓名：{{ item.psnData.psnName }}</em>
                </div>
                <!-- 调配信息 -->
                <div class="row clearfix person">
                  <span class="fl">调配信息</span>
                  <em class="fr">
                    调配方式：{{ item.psnData.stapplyMode }} 调配业务类型：{{ item.psnData.trnsType }} 是否试用：{{ item.psnData.trialFlag }}<br>
                    调配原因：{{ item.psnData.sreason }} <br>
                    生效日期：{{ item.psnData.effectDate }}   岗位试用期限：{{ item.psnData.trialDays }} <br>
                    试用期限：{{ item.psnData.trialBeginDate }} 至 {{ item.psnData.trialEndDate }}<br>
                    调配说明：{{ item.psnData.memo }}
                    </em>
                </div>
              </div>
            </van-checkbox>
            </div>
          </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

        <van-tab title="入职" name="entry" v-if="isAuth('entry')">
          <van-checkbox-group v-model="checked" @change="handleCheck">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.entry"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('entry', item.billId)" style="marginTop:10px;">
                <h4>入职申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr">
                    员工号：{{item.psnData.psnCode}}
                    姓名：{{item.psnData.psnName}}<br>
                    组织：{{item.psnData.orgName}}
                    部门：{{ item.psnData.deptName }}<br>
                    岗位：{{ item.psnData.postName }}
                    人员类别：{{ item.psnData.psnclName }}<br>
                    入职类型：{{ item.psnData.trnsType }}
                    生效日期：{{ item.psnData.effectDate }}<br>
                    身份证：{{ item.psnData.idCard }}
                    手机：{{ item.psnData.mobile }}
                    </em>
                </div>
              </div>
            </van-checkbox>
            </div>
          </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

       <van-tab title="离职" name="dimission" v-if="isAuth('dimission')">
          <van-checkbox-group v-model="checked" @change="handleCheck">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.dimission"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('dimission', item.billId)" style="marginTop:10px;">
                <h4>离职申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr">
                    员工号：{{item.psnData.psnCode}}
                    姓名：{{ item.psnData.psnName }}</em>
                </div>
                <!-- 离职信息 -->
                <div class="row clearfix person">
                  <span class="fl">离职信息</span>
                  <em class="fr">
                    离职业务类型：{{ item.psnData.dimissionType }} 生效日期：{{ item.psnData.effectDate }}<br>
                    离职原因：{{ item.psnData.sreason }} <br>
                    离职说明：{{ item.psnData.memo }}
                    </em>
                </div>
              </div>
            </van-checkbox>
            </div>
          </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

        <van-tab title="转正" name="regular" v-if="isAuth('regular')">
          <van-checkbox-group v-model="checked" @change="handleCheck">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.regular"
              :key="item.billId"
            >
            <van-checkbox class="item"  :name="item.billId" :key="item.billId">
              <div class="title" @click="auditDetail('regular', item.billId)" style="marginTop:10px;">
                <h4>转正申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 申请单编码 -->
                <div class="row clearfix">
                  <span class="fl">申请单编码</span>
                  <em class="num fr">{{ item.billCode }}</em>
                </div>
                <!-- 申请日期 -->
                <div class="row clearfix">
                  <span class="fl">申请日期</span>
                  <em class="startTime fr">{{ item.applyDate }}</em>
                </div>
                <!-- 申请人 -->
                <div class="row clearfix">
                  <span class="fl">申请人</span>
                  <em class="startTime fr">{{ item.billMaker }}</em>
                </div>
                <!-- 员工信息 -->
                <div class="row clearfix person">
                  <span class="fl">员工信息</span>
                  <em class="fr">
                    员工号：{{item.psnData.psnCode}}
                    姓名：{{ item.psnData.psnName }}</em>
                </div>
                <!-- 转正信息 -->
                <div class="row clearfix person">
                  <span class="fl">转正信息</span>
                  <em class="fr">
                    试用类型：{{ item.psnData.probationType }} 生效日期：{{ item.psnData.regularDate }}<br>
                    试用开始日期：{{ item.psnData.beginDate }} 结束日期：{{ item.psnData.endDate }} <br>
                    试用结果：{{ item.psnData.trialResult }}
                    </em>
                </div>
              </div>
            </van-checkbox>
            </div>
          </van-pull-refresh>
          </van-checkbox-group>
        </van-tab>

        <van-tab title="登记" name="signin" v-if="isAuth('signin')">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.signin"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('signin', item.billId)" style="marginTop:10px;">
                <h4>登记申请</h4>
                <span class="waitState">待审批</span>
              </div>
              <div class="details">
                <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 客户名称 -->
                <div class="row clearfix">
                  <span class="fl">客户名称</span>
                  <em class="startTime fr">{{ item.custName }}</em>
                </div>
                <!-- 微信名 -->
                <div class="row clearfix">
                  <span class="fl">微信名</span>
                  <em class="startTime fr">{{ item.nickname }}</em>
                </div>
                <!-- 联系人 -->
                <div class="row clearfix">
                  <span class="fl">联系人</span>
                  <em class="startTime fr">{{ item.name }}</em>
                </div>
                <!-- 电话 -->
                <div class="row clearfix">
                  <span class="fl">电话</span>
                  <em class="startTime fr">{{ item.phone }}</em>
                </div>
                <!-- 联系地址 -->
                <div class="row clearfix">
                  <span class="fl">联系地址</span>
                  <em class="startTime fr">{{ item.address }}</em>
                </div>
                <!-- 应用权限 -->
                <!-- <div class="row clearfix person">
                  <span class="fl">应用权限</span>
                  <em class="startTime fr" v-if = "item.roleRequire">客户建议</em>
                  <em class="startTime fr" v-if = "item.roleComsug">客户投诉</em>
                  <em class="startTime fr" v-if = "item.roleRepair">报事报修</em>
                </div> -->
              </div>
            </div>
          </van-pull-refresh>
        </van-tab>

        <van-tab title="客户建议" name="require" v-if="isAuth('require')">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.require"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('require', item.billId)" style="marginTop:10px;">
                <h4>客户建议</h4>
                <span class="waitState">{{item.crmStatusName}}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                  <!-- custName -->
                  <div class="row clearfix">
                    <span class="fl">单位名称</span>
                    <em class="startTime fr">{{ item.custName }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 投诉内容 -->
                <div class="row clearfix">
                  <span class="fl">投诉内容</span>
                  <em class="startTime fr">{{ item.problemDesc }}</em>
                </div>
                 <!-- 联系人 -->
                 <div class="row clearfix">
                  <span class="fl">联系人</span>
                  <em class="startTime fr">{{ item.name }}</em>
                </div>
                 <!-- 电话 -->
                <div class="row clearfix">
                  <span class="fl">电话</span>
                  <em class="startTime fr">{{ item.phone }}</em>
                </div>
              </div>
            </div>
          </van-pull-refresh>
        </van-tab>

        <van-tab title="客户投诉" name="comsug" v-if="isAuth('comsug')">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.comsug"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('comsug', item.billId)" style="marginTop:10px;">
                <h4>客户投诉</h4>
                <span class="waitState">{{item.crmStatusName}}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                  <!-- custName -->
                  <div class="row clearfix">
                    <span class="fl">单位名称</span>
                    <em class="startTime fr">{{ item.custName }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 投诉内容 -->
                <div class="row clearfix">
                  <span class="fl">投诉内容</span>
                  <em class="startTime fr">{{ item.problemDesc }}</em>
                </div>
                 <!-- 联系人 -->
                 <div class="row clearfix">
                  <span class="fl">联系人</span>
                  <em class="startTime fr">{{ item.name }}</em>
                </div>
                 <!-- 电话 -->
                <div class="row clearfix">
                  <span class="fl">电话</span>
                  <em class="startTime fr">{{ item.phone }}</em>
                </div>
              </div>
            </div>
          </van-pull-refresh>
        </van-tab>

        <van-tab title="报事报修" name="repair" v-if="isAuth('repair')">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.repair"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('repair', item.billId)" style="marginTop:10px;">
                <h4>报事报修</h4>
                <span class="waitState">{{item.crmStatusName}}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 维修部位 -->
                <div class="row clearfix">
                  <span class="fl">维修部位</span>
                  <em class="startTime fr">{{ item.repairPlace }}</em>
                </div>
                <!-- 维修内容 -->
                <div class="row clearfix">
                  <span class="fl">维修内容</span>
                  <em class="startTime fr">{{ item.repairNote }}</em>
                </div>
                <!-- 维修联系人 -->
                <div class="row clearfix">
                  <span class="fl">维修联系人</span>
                  <em class="startTime fr">{{ item.repairPsn }}</em>
                </div>
                <!-- 维修联系电话 -->
                <div class="row clearfix">
                  <span class="fl">维修联系电话</span>
                  <em class="startTime fr">{{ item.repairPhone }}</em>
                </div>
                 <!-- 派单人 -->
                 <div class="row clearfix">
                  <span class="fl">派单人</span>
                  <em class="startTime fr">{{ item.processorUser }}</em>
                </div>
                 <!-- 派单时间 -->
                <div class="row clearfix">
                  <span class="fl">派单时间</span>
                  <em class="startTime fr">{{ item.processorTime }}</em>
                </div>
                <!-- 派单说明 -->
                <div class="row clearfix">
                  <span class="fl">派单说明</span>
                  <em class="startTime fr">{{ item.processorResult }}</em>
                </div>
                <!-- 回访人 -->
                <div class="row clearfix">
                  <span class="fl">回访人</span>
                  <em class="startTime fr">{{ item.revisitUser }}</em>
                </div>
                 <!-- 回访时间 -->
                <div class="row clearfix">
                  <span class="fl">回访时间</span>
                  <em class="startTime fr">{{ item.revisitTime }}</em>
                </div>
                <!-- 回访说明 -->
                <div class="row clearfix">
                  <span class="fl">回访说明</span>
                  <em class="startTime fr">{{ item.revisitMemo }}</em>
                </div>
              </div>
            </div>
          </van-pull-refresh>
        </van-tab>

        <van-tab title="信息变更" name="infochange" v-if="isAuth('infochange')">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.infochange"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('infochange', item.billId)" style="marginTop:10px;">
                <h4>信息变更</h4>
                <span class="waitState">详情</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 变更内容 -->
                <div class="row clearfix">
                  <span class="fl">变更内容</span>
                  <em class="startTime fr">{{ item.changeInfo }}</em>
                </div>
              </div>
            </div>
          </van-pull-refresh>
        </van-tab>

        <van-tab title="员工建议" name="emprequire" v-if="isAuth('emprequire')">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.emprequire"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('emprequire', item.billId)" style="marginTop:10px;">
                <h4>员工建议</h4>
                <span class="waitState">{{ item.crmStatusName }}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                <!-- 建议内容 -->
                <div class="row clearfix">
                  <span class="fl">建议内容</span>
                  <em class="startTime fr">{{ item.problemDesc }}</em>
                </div>
                 <!-- 处理人 -->
                 <div class="row clearfix">
                  <span class="fl">处理人</span>
                  <em class="startTime fr">{{ item.processorUser }}</em>
                </div>
                 <!-- 处置时间 -->
                <div class="row clearfix">
                  <span class="fl">处置时间</span>
                  <em class="startTime fr">{{ item.processorTime }}</em>
                </div>
                <!-- 处置结果 -->
                <div class="row clearfix">
                  <span class="fl">处置结果</span>
                  <em class="startTime fr">{{ item.processorResult }}</em>
                </div>
              </div>
            </div>
          </van-pull-refresh>
        </van-tab>

      </van-tabs>
    </div>

<div class="selectAll" v-if="checkType.includes(busiType)" >
  <!-- 全选按钮 -->
          <div class="allselectBgc">
              <van-checkbox v-model="checkAll"  @change="handleCheckAll">全选
              </van-checkbox>
              <van-button type="primary" @click="pass" v-bind:class="{darkBtn: this.checked.length === 0,brightBtn:this.checked.length > 0}" v-loading.fullscreen.lock="fullscreenLoading" >审批 ({{ this.checked.length }})</van-button>
          </div>
</div>

    <!-- 底部 -->
    <div class="footer">
      <ul>
        <li>
          <div @click="$router.push({ name: 'Home' })">
            <i class="el-icon-s-home big"></i>
            <p>首页</p>
          </div>
        </li>
        <li>
          <div>
            <i class="el-icon-s-order current"></i>
            <p>待办</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'WorkBench' })">
            <i class="el-icon-s-platform"></i>
            <p>工作台</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'My' })">
            <i class="el-icon-s-custom"></i>
            <p>我的</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checkType: ['ALL', 'expense', 'leave', 'leaveoff', 'overtime', 'paybill', 'receipt', 'away', 'changeshift', 'payroll', 'trans', 'dimission', 'entry', 'regular'],
      listinfo: {
        expense: [],
        leave: [],
        leaveoff: [],
        overtime: [],
        paybill: [],
        receipt: [],
        away: [],
        changeshift: [],
        payroll: [],
        trans: [],
        dimission: [],
        entry: [],
        regular: [],
        signin: [],
        require: [],
        comsug: [],
        repair: [],
        infochange: [],
        emprequire: []
      },
      allBills: [], // 所有单据集合，用于全部页签，全选
      isLoading: false,
      fullscreenLoading: false, // 加载中图层蒙版显示
      aprRole: {},
      busiType: 'ALL', // 默认所有
      checked: [], // check选中数据
      checkAll: false// 全选
    }
  },
  created () {
    // 根据权限加载页签
    this.aprRole = JSON.parse(localStorage.getItem('aprRole'))
    // 加载数据
    const { busiType } = this.$route.params
    if (busiType) {
      localStorage.setItem('busi_type', busiType)
      this.busiType = busiType
    } else {
      this.busiType = localStorage.getItem('busi_type')
    }
    this.queryList(this.busiType)
  },
  methods: {
    // 是否有权限
    isAuth (busiType) {
      let isRole = false
      if (this.aprRole.cwRole) {
        this.aprRole.cwRole.roles.find((item) => {
          if (busiType === item.rcode) {
            isRole = true
          }
        })
      }
      if (this.aprRole.hrRole) {
        this.aprRole.hrRole.roles.find((item) => {
          if (busiType === item.rcode) {
            isRole = true
          }
        })
      }
      if (this.aprRole.xzRole) {
        this.aprRole.xzRole.roles.find((item) => {
          if (busiType === item.rcode) {
            isRole = true
          }
        })
      }
      if (this.aprRole.crmRole) {
        this.aprRole.crmRole.roles.find((item) => {
          if (busiType === item.rcode) {
            isRole = true
          }
        })
      }
      return isRole
    },
    // 全选
    handleCheckAll (val) {
      const checkedCount = this.checked.length
      if (this.allBills != null) {
        if (val) {
          this.checked = this.allBills.map(item => item.billId)
        } else if (checkedCount === this.allBills.length) {
          this.checked = []
        }
      }
    },
    handleCheck (val) {
      if (this.checked != null && this.allBills != null && this.allBills.length > 0) {
        this.checkAll = this.checked.length === this.allBills.length
      }
    },
    // 点击单选
    pass () {
      if (this.checked.length === 0) {
        this.$toast.fail('请至少选择一条需审批的数据')
        return
      }
      const passData = this.getResult(this.checked, this.allBills)
      // 添加用户审批信息
      const userId = localStorage.getItem('user_id')
      passData.auditUserId = userId
      passData.variable = 'PASS'
      passData.auditSuggestion = '批准'
      // 批量审批
      this.fullscreenLoading = true
      this.$axios
        .post('/ApproveBillBatchService', passData)
        .then(res => {
          this.fullscreenLoading = false
          const { statusCode, msg } = res.data
          if (statusCode === '200') {
            // 轻提示框
            this.$toast.success(msg)
            // 审批成功后,刷新
            this.onRefresh()
          } else {
            // 否则轻提示登录信息有误
            this.$toast.fail(msg)
          }
        })
        .catch(eror => {
          this.fullscreenLoading = false
          this.$toast.fail('接口请求异常，请联系管理员：' + eror)
        })
    },
    // tabs切换
    handleClick () {
      localStorage.setItem('busi_type', this.busiType)
      this.queryList(this.busiType)
    },
    async queryList (busiType) {
      // 每次加载数据清空选择
      this.checkAll = false
      this.checked = []
      // 从本地获取用户 id 和 token
      const userId = localStorage.getItem('user_id')
      // 发送获取个人信息的请求时，需要携带上token令牌，需要放在请求头Authorization字段上
      await this.$axios
        .get('/ApproveQryListService', {
          params: {
            userId: userId,
            busiType: busiType
          }
        })
        .then((res) => {
          const { statusCode, data } = res.data
          if (statusCode === '200') {
            this.listinfo = data
            this.allBills = [] // 先清空
            // 同时放入所有集合
            this.pushList(this.listinfo.expense)
            this.pushList(this.listinfo.leave)
            this.pushList(this.listinfo.leaveoff)
            this.pushList(this.listinfo.overtime)
            this.pushList(this.listinfo.paybill)
            this.pushList(this.listinfo.receipt)
            this.pushList(this.listinfo.away)
            this.pushList(this.listinfo.changeshift)
            this.pushList(this.listinfo.payroll)
            this.pushList(this.listinfo.trans)
            this.pushList(this.listinfo.dimission)
            this.pushList(this.listinfo.entry)
            this.pushList(this.listinfo.regular)
            // this.pushList(this.listinfo.signin)
            // this.pushList(this.listinfo.require)
            // this.pushList(this.listinfo.comsug)
            // this.pushList(this.listinfo.repair)
            // this.pushList(this.listinfo.infochange)
            // this.pushList(this.listinfo.emprequire)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // 将每种业务集合放一起，全选需要
    pushList (list) {
      if (list != null && list.length > 0) {
        for (let index = 0; index < list.length; index++) {
          this.allBills.push(list[index])
        }
      }
    },
    // 获取选择的checked转化为对应的业务集合
    getResult (checked, list) {
      const passData = {}
      // 挨个匹配不同类型 expense leave leaveoff overtime paybill receipt away changeshift
      passData.expense = this.getListByChecked(this.listinfo.expense, checked)
      passData.leave = this.getListByChecked(this.listinfo.leave, checked)
      passData.leaveoff = this.getListByChecked(this.listinfo.leaveoff, checked)
      passData.overtime = this.getListByChecked(this.listinfo.overtime, checked)
      passData.paybill = this.getListByChecked(this.listinfo.paybill, checked)
      passData.receipt = this.getListByChecked(this.listinfo.receipt, checked)
      passData.away = this.getListByChecked(this.listinfo.away, checked)
      passData.changeshift = this.getListByChecked(this.listinfo.changeshift, checked)
      passData.payroll = this.getListByChecked(this.listinfo.payroll, checked)
      passData.trans = this.getListByChecked(this.listinfo.trans, checked)
      passData.dimission = this.getListByChecked(this.listinfo.dimission, checked)
      passData.entry = this.getListByChecked(this.listinfo.entry, checked)
      passData.regular = this.getListByChecked(this.listinfo.regular, checked)
      // passData.signin = this.getListByChecked(this.listinfo.signin, checked)
      // passData.require = this.getListByChecked(this.listinfo.require, checked)
      // passData.comsug = this.getListByChecked(this.listinfo.comsug, checked)
      // passData.repair = this.getListByChecked(this.listinfo.repair, checked)
      // passData.infochange = this.getListByChecked(this.listinfo.infochange, checked)
      // passData.emprequire = this.getListByChecked(this.listinfo.emprequire, checked)
      return passData
    },
    // 根据checked 和 每个业务对象集合获取选中的集合
    getListByChecked (list, checked) {
      const backList = []
      for (let index = 0; index < checked.length; index++) {
        const billId = checked[index]
        if (list) {
          for (let j = 0; j < list.length; j++) {
            if (list[j].billId === billId) {
              backList.push(list[j])
            }
          }
        }
      }
      return backList
    },
    auditDetail (busiType, billId) {
      if (busiType === 'infochange') {
        const pushName = busiType[0].toUpperCase() + busiType.substr(1) + 'Detail'
        this.$router.push({
          name: pushName,
          params: {
            busiType: busiType,
            billId: billId
          }
        })
      } else {
        // 调整到审批页面,审批页面组件=业务类型+Audit
        const pushName = busiType[0].toUpperCase() + busiType.substr(1) + 'Audit'
        this.$router.push({
          name: pushName,
          params: {
            busiType: busiType,
            billId: billId
          }
        })
      }
    },
    // 下拉刷新
    onRefresh () {
      setTimeout(() => {
        this.$toast('刷新成功')
        this.isLoading = false
        this.queryList(this.busiType)
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.tdList {
  height: 100%;
  // 单个订单
  .wait1,
  .completed1,
  .completed2 {
    border-radius: 7px;
    margin-top: 10px;
    padding: 10px;
    box-shadow: 2px 2px 10px rgba(121, 118, 116, 0.4);
    margin: 10px;
    // background-color: yellow;
  }
}
.waitState {
  font-size: 0.3rem;
}

.allselectBgc {
  height: 100px;
  padding: 20px 10px;
  .darkBtn {
    float: right;
    background-color: rgb(245, 238, 238);
    border: none;
    color: gray;
    border-radius: 20px;
    padding: 10px 20px;
    height: 30px;
  }
  .brightBtn {
    float: right;
    background:rgb(93,142,218);
    color:white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    height: 30px;
  }
}
</style>
