import Vue from 'vue'
import App from './App.vue'
import router from './router'

import axios from 'axios' // 优化axios,绑定到原型上

import ElementUI from 'element-ui'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import moment from 'moment'
import 'lib-flexible' // 自适应

import 'vant/lib/button/style'
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.css' // font-awesome图标
import '../src/css/override-ele.scss' // 覆盖ele，默认样式

import tool from '../src/util/tool'

import { Icon, Field, Tab, Tabs, Toast, NoticeBar, PullRefresh, Button, Form, Cell, CellGroup, Checkbox, CheckboxGroup, Loading, Divider, DatetimePicker, Grid, GridItem, List, Popup, Col, Row, Badge, Collapse, CollapseItem } from 'vant'
import MyHeader from 'components/my-header.vue'
import MyNav from 'components/my-nav.vue'

Vue.component('MyHeader', MyHeader)
Vue.component('MyNav', MyNav)
Vue.use(ElementUI)
Vue.use(Icon)
Vue.use(Field) // 输入框
Vue.use(Tab) // 标签页
Vue.use(Tabs) // 标签页
Vue.use(Toast) // 轻提示
Vue.use(NoticeBar) // 通知栏
Vue.use(PullRefresh) // 下拉刷新
Vue.use(Button) // 按钮
Vue.use(Form) // 表单
Vue.use(Cell) // Cell
Vue.use(CellGroup) // CellGroup
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Loading)
Vue.use(tool)
Vue.use(Divider)
Vue.use(DatetimePicker)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(List)
Vue.use(Popup)
Vue.use(Row)
Vue.use(Col)
Vue.use(Badge)
Vue.use(Collapse)
Vue.use(CollapseItem)

// 图片加载插件
Vue.use(Viewer, { name: 'viewer' })

// 去掉控制台的错
Vue.config.productionTip = false

// axios优化，绑定到原型上
Vue.prototype.$axios = axios

Vue.filter('dateFormat', (str, partten = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(str).format(partten)
})

// 配置基准路径，之后的ajax请求可以直接拼接上前缀地址
axios.defaults.baseURL = '/api'

// 全局配置请求响应时间s
axios.defaults.timeout = 50000

// 表示跨域请求时是否需要使用凭证
axios.defaults.withCredentials = false

// 请求拦截器 每次请求先到此，判断有无 token ==========================
axios.interceptors.request.use(
  function (config) {
    // 在发送请求前，config，就是请求想换的内容
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.token = token
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// 响应拦截器 拦截过期 token ===========================================
axios.interceptors.response.use(
  function (response) {
    // 对响应的数据做判断(response就是响应的数据)
    // 如果后台给出的状态是401或者是错误信息提示，则证明将token移除
    if (
      response.data.statusCode === 401 &&
      response.data.msg === '你还未登录或登录已过期，请先登录'
    ) {
      // token过期，移除，和用户信息
      localStorage.removeItem('token')
      localStorage.removeItem('user_id')
      localStorage.removeItem('user_name')
      // token验证失败，轻提示 + 拦截到登录页
      Toast('尊敬的用户，登录已过期，请重新登录')
      router.push('/login')
    }
    return response // response就是响应的数据
  },
  function (error) {
    return Promise.reject(error)
  }
)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
