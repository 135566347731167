<template>
  <div class="home">
    <!-- 系统公告 -->
    <div class="notice">
      <van-notice-bar left-icon="volume-o" scrollable>
        <span v-if="totalnum > 0 ">待办提醒：您一共有{{ totalnum }}条待办任务需审批，请及时处理！</span>
        <span v-if="totalnum == 0 ">待办提醒：您暂时没有需要处理的待办事项！</span>
      </van-notice-bar>
    </div>

    <van-collapse v-model="activeNames">

      <van-collapse-item :name="aprRole.cwRole.busiType" v-if="aprRole.cwRole!=null" class="vci">
        <div slot="title" style="font-size:18px;font-weight: 700">{{aprRole.cwRole.busiType}}</div>
        <van-grid :column-num="4">
          <van-grid-item v-for="item in aprRole.cwRole.roles" :key="item.rcode" :text="item.rname" @click="toDoList(item.rcode)" >
            <van-badge :content="item.rcount" max="99" class="vanbadge">
              <img :src="require('../assets/apr_paybill.png')" v-if="item.rcode === 'paybill'"/>
              <img :src="require('../assets/apr_expense.png')" v-if="item.rcode === 'expense'"/>
              <img :src="require('../assets/apr_receipt.png')" v-if="item.rcode === 'receipt'"/>
              <p class="p">{{item.rname}}</p>
            </van-badge>
          </van-grid-item>
        </van-grid>
      </van-collapse-item>

      <van-collapse-item :name="aprRole.hrRole.busiType" v-if="aprRole.hrRole!=null" class="vci">
        <div slot="title" style="font-size:18px;font-weight: 700">{{aprRole.hrRole.busiType}}</div>
        <van-grid :column-num="4">
          <van-grid-item v-for="item in aprRole.hrRole.roles" :key="item.rcode" :text="item.rname" @click="toDoList(item.rcode)" >
            <van-badge :content="item.rcount" max="99" class="vanbadge">
              <!-- <van-icon class="fa fa-calendar-check-o icon" v-if="item.rcode == 'leave'"/>
              <van-icon class="fa fa-briefcase icon" v-if="item.rcode == 'overtime'"/>
              <van-icon class="fa fa-briefcase icon" v-if="item.rcode == 'away'"/>
              <van-icon class="fa fa-retweet icon" v-if="item.rcode == 'changeshift'"/>
              <van-icon class="fa fa-arrow-up icon" v-if="item.rcode == 'trans'"/>
              <van-icon class="fa fa-sign-out icon" v-if="item.rcode == 'dimission'"/>
              <van-icon class="fa fa-suitcase icon" v-if="item.rcode == 'entry'"/>
              <van-icon class="fa fa-server icon" v-if="item.rcode == 'regular'"/>-->
              <img :src="require('../assets/apr_leave.png')" v-if="item.rcode === 'leave'"/>
              <img :src="require('../assets/apr_trans.png')" v-if="item.rcode === 'trans'"/>
              <img :src="require('../assets/apr_overtime.png')" v-if="item.rcode === 'overtime'"/>
              <img :src="require('../assets/apr_away.png')" v-if="item.rcode === 'away'"/>
              <img :src="require('../assets/apr_entry.png')" v-if="item.rcode === 'entry'"/>
              <img :src="require('../assets/apr_dimission.png')" v-if="item.rcode === 'dimission'"/>
              <img :src="require('../assets/apr_regular.png')" v-if="item.rcode === 'regular'"/>
              <img :src="require('../assets/apr_changeshift.png')" v-if="item.rcode === 'changeshift'"/>
              <p class="p">{{item.rname}}</p>
            </van-badge>
          </van-grid-item>
        </van-grid>
      </van-collapse-item>

      <van-collapse-item :name="aprRole.xzRole.busiType" v-if="aprRole.xzRole!=null" class="vci">
        <div slot="title" style="font-size:18px;font-weight: 700">{{aprRole.xzRole.busiType}}</div>
        <van-grid :column-num="4">
          <van-grid-item v-for="item in aprRole.xzRole.roles" :key="item.rcode" :text="item.rname" @click="toDoList(item.rcode)" >
            <van-badge :content="item.rcount" max="99" class="vanbadge">
              <img :src="require('../assets/apr_payroll.png')" v-if="item.rcode === 'payroll'"/>
               <img :src="require('../assets/apr_payslip.png')" v-if="item.rcode === 'payslip'"/>
              <p class="p">{{item.rname}}</p>
            </van-badge>
          </van-grid-item>
        </van-grid>
      </van-collapse-item>

      <van-collapse-item :name="aprRole.crmRole.busiType" v-if="aprRole.crmRole!=null" class="vci">
        <div slot="title" style="font-size:18px;font-weight: 700">{{aprRole.crmRole.busiType}}</div>
        <van-grid :column-num="4">
          <van-grid-item v-for="item in aprRole.crmRole.roles" :key="item.rcode" :text="item.rname" @click="toDoList(item.rcode)" >
            <van-badge :content="item.rcount" max="99" class="vanbadge">
              <img :src="require('../assets/apr_signin.png')" v-if="item.rcode === 'signin'"/>
              <img :src="require('../assets/apr_comsug.png')" v-if="item.rcode === 'comsug'"/>
              <img :src="require('../assets/apr_require.png')" v-if="item.rcode === 'require'"/>
              <img :src="require('../assets/apr_repair.png')" v-if="item.rcode === 'repair'"/>
              <img :src="require('../assets/apr_infochange.png')" v-if="item.rcode === 'infochange'"/>
              <img :src="require('../assets/apr_emprequire.png')" v-if="item.rcode === 'emprequire'"/>
              <p class="p">{{item.rname}}</p>
            </van-badge>
          </van-grid-item>
        </van-grid>
      </van-collapse-item>

    </van-collapse>

    <!-- 底部 -->
    <div class="footer">
      <ul>
        <li>
          <div>
            <i class="el-icon-s-home big current"></i>
            <p class="current">首页</p>
          </div>
        </li>
        <li>
          <div
            @click="
              $router.push({ name: 'TdList'})
            "
          >
            <i class="el-icon-s-order"></i>
            <p>待办</p>
          </div>
        </li>
        <li>
          <div
            @click="
              $router.push({ name: 'WorkBench'})
            "
          >
            <i class="el-icon-s-platform"></i>
            <p>工作台</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'My'})">
            <i class="el-icon-s-custom"></i>
            <p>我的</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      totalnum: 0,
      aprRole: {},
      creRole: {},
      activeNames: [],
      aprRoleAry: [],
      creRoleAry: []
    }
  },
  // 得到用户输入的登录信息
  async created () {
    this.activeNames = ['HR流程', '财务流程', '薪资发放流程', '薪资', 'CRM管理']
    // 从本地获取用户 id 和 token
    const userId = localStorage.getItem('user_id')
    // 发送获取个人信息的请求时，需要携带上token令牌，需要放在请求头Authorization字段上
    // const res = await this.$axios.get(`/ApproveQryTotalService?userId=${userId}`)
    await this.$axios.get('/ApproveQryTotalService', {
      params: {
        userId: userId
      }
    }
    ).then((res) => {
      const { statusCode, data } = res.data
      if (statusCode === '200') {
        console.log('data:' + data)
        this.totalnum = data.totalnum
        this.aprRole = data.aprRole
        this.creRole = data.creRole
        localStorage.setItem('aprRole', JSON.stringify(data.aprRole))
        localStorage.setItem('creRole', JSON.stringify(data.creRole))
      }
    })
      .catch((error) => {
        console.log(error)
      })
  },
  methods: {
    toDoList (busiType) {
      // 调转到待办，传递参数业务类型
      if (busiType === 'payslip') {
        this.$router.push({
          name: 'PayslipList'
        })
      } else {
        this.$router.push({
          name: 'TdList',
          params: {
            busiType: busiType
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  padding: 10px 10px 50px 10px;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 40px;
    color: rgb(95, 91, 91);
  }
  .banner {
    height: 100px;
    border: 1px solid RGB(93,142,218);
    margin: 30px 0;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.vci {
  .title-class{
    font-size: 25px;
  }
}
.icon {
  font-size: 24px;
  background-image: -webkit-gradient(
      linear,
      left center,
      right center,
      from(rgb(93, 142, 218, 0.8)),
      to(rgb(93, 142, 218, 1)),
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.p {
  font-size: 12px;
}
.vanbadge{
  text-align: center;
}

 ::v-deep .van-collapse-item__content {
    padding: 0rem 0rem;
    color: #969799;
    font-size: 0.376344rem;
    line-height: 0;
    background-color: #fff;
}

</style>
