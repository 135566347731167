<template>
  <div class="billForm">
    <my-header>客户投诉评价</my-header>

    <!-- 表单内容 -->
    <el-form
      :model="billForm"
      :rules="rules"
      ref="billRef"
      label-width="100px"
      class="billForm"
    >

    <div class="titel">申请信息</div>
    <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">单据号</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
            <el-form-item class="imright">
              <span>{{billForm.billCode}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">单据日期</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
            <el-form-item class="imright">
              <span>{{billForm.applyDate}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">管理处</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
           <el-form-item class="imright">
              <span>{{billForm.deptName}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">投诉内容</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
           <el-form-item class="imright">
              <span>{{billForm.problemDesc}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">照片</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
          <div class='d-pic'>
            <viewer :images="billForm.fileData">
              <img v-for="src in billForm.fileData" :src="src.fileUrl" :key="src.fileName" class="m-pic" />
            </viewer>
          </div>
        </el-col>
      </el-row>

      <div class="titel">处置信息</div>
      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">处置时间</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
           <el-form-item class="imright">
            <span>{{billForm.processorTime}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">处置结果</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
           <el-form-item class="imright">
              <span>{{billForm.processorResult}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <!-- 评价完成 -->
      <div v-if="billForm.crmStatus == 'FINEVAL'">
        <div class="titel">评价</div>
        <el-row style="margin-top: 10px;">
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">满意度</h3>
            </el-form-item>
         </el-col>
         <el-col :span="15">
           <el-form-item class="imright">
            <el-rate v-model="billForm.satisfaction" :show-text="false" disabled></el-rate>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">评价</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
           <el-form-item class="imright">
              <span>{{billForm.evaluate}}</span>
            </el-form-item>
        </el-col>
      </el-row>
      </div>

      <!-- 待评价 -->
      <div v-if="billForm.crmStatus == 'UNEVAL'">
        <div class="titel">待评价</div>
        <el-row style="margin-top: 10px;">
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">满意度</h3>
            </el-form-item>
         </el-col>
         <el-col :span="15">
           <el-form-item class="imright">
            <el-rate v-model="billForm.satisfaction" :show-text="false" clearable></el-rate>
            </el-form-item>
        </el-col>
      </el-row>
      <el-form-item prop="evaluate">
        <h3>评价</h3>
        <el-input
          type="textarea"
          :maxlength="500" :autosize="{ minRows: 5, maxRows: 10}"
          placeholder="请输入评价"
          v-model="billForm.evaluate"
        >
        </el-input>
      </el-form-item>
       <!-- 提交 -->
       <el-form-item>
        <el-button @click.prevent="submit()" class="subButton" style="margin-top: 20px;"
        v-loading.fullscreen.lock="fullscreenLoading" >提交</el-button>
      </el-form-item>
      </div>

    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      billId: '',
      busiType: '',
      dialogVisible: false,
      fullscreenLoading: false, // 加载中图层蒙版显示
      billForm: {
        deptName: '',
        billId: '',
        crmBusiType: '',
        problemDesc: '',
        fileData: [],
        pk_crm_wechat_trussup: '',
        auditBy: '', // 审批人
        auditUserId: '', // 审批人id
        variable: '' // 审批是否通过
      },
      rules: { // 校验规则

      }
    }
  },
  created () {
    // this.$route当前路由的相关信息(path/query/params)
    const { busiType, billId } = this.$route.params
    if (billId) {
      localStorage.setItem('billId', billId)
      localStorage.setItem('busiType', busiType)
      this.billId = billId
      this.busiType = busiType
    } else {
      this.billId = localStorage.getItem('billId')
      this.busiType = localStorage.getItem('busiType')
    }
    this.getOrderDetail(this.busiType, this.billId)
  },
  methods: {
    async getOrderDetail (busiType, billId) {
      // 从本地获取用户 id 和 token
      // 发送获取个人信息的请求时，需要携带上token令牌
      const pkCrm = localStorage.getItem('pk_crm_wechat_trussup')
      // const res = await this.$axios.get(`/ApproveQryDetailService?busiType=${busiType}&billId=${billId}&userId=${userId}`)
      await this.$axios.get('/AppCrmQryDetailService', {
        params: {
          busiType: busiType,
          billId: billId,
          pk_crm_wechat_trussup: pkCrm
        }
      }
      ).then((res) => {
        const { statusCode, data } = res.data
        if (statusCode === '200') {
          this.billForm = data.comsug
        }
      })
        .catch((error) => {
          console.log(error)
        })
    },
    submit () {
      this.$refs.billRef.validate(valid => {
        this.billForm.auditUserId = localStorage.getItem('pk_crm_wechat_trussup')
        this.billForm.auditBy = localStorage.getItem('name')
        this.billForm.billType = 'comsug'
        this.fullscreenLoading = true // 提交时显示加载中蒙版
        this.$axios
          .post('/AppCrmEvaluateBillService', this.billForm)
          .then(res => {
            const { statusCode, msg } = res.data
            this.fullscreenLoading = false // 提交时完成后取消加载中蒙版
            if (statusCode === '200') {
              // 轻提示框
              this.$toast.success(msg)
              // 审批成功后，跳转到首页
              this.$router.push('/sHTdList')
            } else {
              // 否则轻提示登录信息有误
              this.$toast.fail(msg)
            }
          })
          .catch(eror => {
            this.fullscreenLoading = false
            this.$toast.fail('接口请求异常，请联系管理员：' + eror)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// 上传区域大框框
.front,
.back {
  width: 100%;
  height: 158px;
  margin-top: 50px;
  text-align: center;
  border: 1px solid #dce4ff;
  border-radius: 5px;
}
.d-pic{
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  width:400px;
  height:100px;
  margin:50px
}

.m-pic {
  max-width: 40%;
  max-height: 50%;
}
</style>
