import Vue from 'vue'
import VueRouter from 'vue-router'

// 页面组件
import Login from '../pages/login.vue'
import Home from '../pages/home.vue'
import TdList from '../pages/tdList.vue'
import WorkBench from '../pages/workBench.vue'
import My from '../pages/my.vue'
import Vuepdf from '../pages/vuepdf.vue'
import VueImg from '../pages/vueImg.vue'
// 单据审批组件
import LeaveAudit from '../pages/leaveAudit.vue'
import ExpenseAudit from '../pages/expenseAudit.vue'
import LeaveoffAudit from '../pages/leaveoffAudit.vue'
import OvertimeAudit from '../pages/overtimeAudit.vue'
import PaybillAudit from '../pages/paybillAudit.vue'
import ReceiptAudit from '../pages/receiptAudit.vue'
import AwayAudit from '../pages/awayAudit.vue'
import ChangeshiftAudit from '../pages/changeshiftAudit.vue'
import PayrollAudit from '../pages/payrollAudit.vue'
import TransAudit from '../pages/transAudit.vue'
import DimissionAudit from '../pages/dimissionAudit.vue'
import EntryAudit from '../pages/entryAudit.vue'
import RegularAudit from '../pages/regularAudit.vue'
import SigninAudit from '../pages/signinAudit.vue'
import RequireAudit from '../pages/requireAudit.vue'
import ComsugAudit from '../pages/comsugAudit.vue'
import RepairAudit from '../pages/repairAudit.vue'
import InfochangeDetail from '../pages/infochangeDetail.vue'
import EmprequireAudit from '../pages/emprequireAudit.vue'
import PayslipList from '../pages/payslipList.vue'
import PayslipDetail from '../pages/payslipDetail.vue'
// 填报组件
import InfochangeForm from '../pages/infochangeForm.vue'
import EmprequireForm from '../pages/emprequireForm.vue'

// 客户供应商自助组件
import ShLogin from '../pages/selfhelp/shLogin.vue'
import CustRegister from '../pages/selfhelp/custRegister.vue'
import SupRegister from '../pages/selfhelp/supRegister.vue'
import ShHome from '../pages/selfhelp/shHome.vue'
import ShTdList from '../pages/selfhelp/shTdList.vue'
import ShWorkBench from '../pages/selfhelp/shWorkBench.vue'
import ShMy from '../pages/selfhelp/shMy.vue'
import Logout from '../pages/selfhelp/logout.vue'

// 填报组件
import ShRequireForm from '../pages/selfhelp/shRequireForm.vue'
import ShComsugForm from '../pages/selfhelp/shComsugForm.vue'
import ShRepairForm from '../pages/selfhelp/shRepairForm.vue'
import ShInfochangeForm from '../pages/selfhelp/shInfochangeForm.vue'
// 评价组件
import ShRequireEval from '../pages/selfhelp/shRequireEval.vue'
import ShComsugEval from '../pages/selfhelp/shComsugEval.vue'
import ShRepairEval from '../pages/selfhelp/shRepairEval.vue'
import ShInfochangeDetail from '../pages/selfhelp/shInfochangeDetail.vue'

Vue.use(VueRouter)
// 解决home页跳转home页时，控制台的报错，不影响代码，但要解决
const originalPush = VueRouter.prototype.push

// 覆写了, 原本的push方法, 将报错捕获
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  // 项目路径
  base: '/ydl_form/',
  routes: [
    { path: '/', redirect: '/Login' }, // 重定向到登录页
    { path: '/Login', name: 'Login', component: Login },
    { path: '/Home', name: 'Home', component: Home },
    { path: '/TdList', name: 'TdList', component: TdList },
    { path: '/WorkBench', name: 'WorkBench', component: WorkBench },
    { path: '/My', name: 'My', component: My },
    { path: '/Vuepdf', name: 'Vuepdf', component: Vuepdf },
    { path: '/VueImg', name: 'VueImg', component: VueImg },

    { path: '/LeaveAudit', name: 'LeaveAudit', component: LeaveAudit },
    { path: '/ExpenseAudit', name: 'ExpenseAudit', component: ExpenseAudit },
    { path: '/LeaveoffAudit', name: 'LeaveoffAudit', component: LeaveoffAudit },
    { path: '/OvertimeAudit', name: 'OvertimeAudit', component: OvertimeAudit },
    { path: '/PaybillAudit', name: 'PaybillAudit', component: PaybillAudit },
    { path: '/ReceiptAudit', name: 'ReceiptAudit', component: ReceiptAudit },
    { path: '/AwayAudit', name: 'AwayAudit', component: AwayAudit },
    { path: '/ChangeshiftAudit', name: 'ChangeshiftAudit', component: ChangeshiftAudit },
    { path: '/PayrollAudit', name: 'PayrollAudit', component: PayrollAudit },
    { path: '/TransAudit', name: 'TransAudit', component: TransAudit },
    { path: '/DimissionAudit', name: 'DimissionAudit', component: DimissionAudit },
    { path: '/EntryAudit', name: 'EntryAudit', component: EntryAudit },
    { path: '/RegularAudit', name: 'RegularAudit', component: RegularAudit },
    { path: '/SigninAudit', name: 'SigninAudit', component: SigninAudit },
    { path: '/RequireAudit', name: 'RequireAudit', component: RequireAudit },
    { path: '/ComsugAudit', name: 'ComsugAudit', component: ComsugAudit },
    { path: '/RepairAudit', name: 'RepairAudit', component: RepairAudit },
    { path: '/InfochangeDetail', name: 'InfochangeDetail', component: InfochangeDetail },
    { path: '/EmprequireAudit', name: 'EmprequireAudit', component: EmprequireAudit },
    { path: '/PayslipList', name: 'PayslipList', component: PayslipList },
    { path: '/PayslipDetail', name: 'PayslipDetail', component: PayslipDetail },
    // 员工填报组件
    { path: '/InfochangeForm', name: 'InfochangeForm', component: InfochangeForm },
    { path: '/EmprequireForm', name: 'EmprequireForm', component: EmprequireForm },
    // 客户供应商自助管理
    { path: '/ShLogin', name: 'ShLogin', component: ShLogin },
    { path: '/CustRegister', name: 'CustRegister', component: CustRegister },
    { path: '/SupRegister', name: 'SupRegister', component: SupRegister },
    { path: '/ShHome', name: 'ShHome', component: ShHome },
    { path: '/ShTdList', name: 'ShTdList', component: ShTdList },
    { path: '/ShWorkBench', name: 'ShWorkBench', component: ShWorkBench },
    { path: '/ShMy', name: 'ShMy', component: ShMy },
    { path: '/Logout', name: 'Logout', component: Logout },
    { path: '/ShRequireForm', name: 'ShRequireForm', component: ShRequireForm },
    { path: '/ShComsugForm', name: 'ShComsugForm', component: ShComsugForm },
    { path: '/ShRepairForm', name: 'ShRepairForm', component: ShRepairForm },
    { path: '/ShInfochangeForm', name: 'ShInfochangeForm', component: ShInfochangeForm },
    { path: '/ShRequireEval', name: 'ShRequireEval', component: ShRequireEval },
    { path: '/ShComsugEval', name: 'ShComsugEval', component: ShComsugEval },
    { path: '/ShRepairEval', name: 'ShRepairEval', component: ShRepairEval },
    { path: '/ShInfochangeDetail', name: 'ShInfochangeDetail', component: ShInfochangeDetail }

  ]
})

// 需授权路径，需有 token
const AuthUrls = [
  '/Home',
  '/My',
  '/TdList',
  '/WorkBench',
  '/LeaveAudit',
  '/ExpenseAudit',
  '/LeaveoffAudit',
  '/OvertimeAudit',
  '/PaybillAudit',
  '/ReceiptAudit',
  '/AwayAudit',
  '/ChangeshiftAudit',
  '/PayrollAudit',
  '/TransAudit',
  '/DimissionAudit',
  '/EntryAudit',
  '/RegularAudit',
  '/SigninAudit',
  '/RequireAudit',
  '/ComsugAudit',
  '/RepairAudit',
  '/InfochangeDetail',
  '/EmprequireAudit',
  '/PayslipList',
  '/PayslipDetail',
  '/InfochangeForm',
  '/EmprequireForm',
  '/ShHome',
  '/ShMy',
  '/Logout',
  '/ShTdList',
  '/ShWorkBench',
  '/ShRequireForm',
  '/ShComsugForm',
  '/ShRepairForm',
  '/ShInfochangeForm',
  '/ShRequireEval',
  '/ShComsugEval',
  '/ShRepairEval',
  '/ShInfochangeDetail'

]
router.beforeEach((to, from, next) => {
  // 到哪去、从哪来、是否放行
  if (AuthUrls.includes(to.path)) {
    const token = localStorage.getItem('token')
    const custType = localStorage.getItem('custType')
    if (token) {
      // 如果有token、放行
      next()
    } else {
      if (custType) {
        router.push('/shLogin') // 无token，拦截到登录, custType不为空，则为自助入口
      } else {
        router.push('/Login') // 无token，拦截到登录
      }
    }
  } else {
    // 不需要授权访问的路径，放行
    next()
  }
})

export default router
