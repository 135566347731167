<template>
  <div class="billForm">
    <my-header>休假审批</my-header>

    <!-- 表单内容 -->
    <el-form
      :model="billForm"
      :rules="rules"
      ref="billRef"
      label-width="100px"
      class="billForm"
    >

      <div class="titel">申请信息</div>
      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">申请单编码</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
           <el-form-item class="imright">
              <span>{{billForm.billCode}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">申请人</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
           <el-form-item class="imright">
              <span>{{billForm.billMaker}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">申请日期</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
           <el-form-item class="imright">
              <span>{{billForm.applyDate}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">休假类别</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
           <el-form-item class="imright">
              <span>{{billForm.leaveType}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">休假总时长</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
           <el-form-item class="imright">
              <span>{{billForm.sumHour}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <div class="bar"></div>
        <h3>员工信息</h3>
        <span class="el-input">部门：{{ billForm.psnData.deptName }} 姓名：{{ billForm.psnData.psnName }} 岗位：{{ billForm.psnData.postName }}</span>
      </el-form-item>

      <el-form-item>
        <div class="bar"></div>
        <h3>请假明细</h3>
        <div v-for="(details,index) in billForm.leaveBData"
                    :key="index">
          <span class="el-input">休假时间：{{ details.leaveBeginTime }}  至 {{ details.leaveEndTime }} <br>
          休假说明：{{ details.leaveRemark }}   </span>
        </div>
      </el-form-item>

      <!-- 审批信息 -->
      <!-- <el-row>
         <el-col :span="9">
            <el-form-item><h3>审批结果</h3></el-form-item>
         </el-col>
         <el-col :span="15">
            <el-form-item prop="variable">
              <div class="el-input">
                <el-radio v-model="billForm.variable" label="PASS">审批通过</el-radio>
                <el-radio v-model="billForm.variable" label="BACK">审批退回</el-radio>
              </div>
            </el-form-item>
          </el-col>
      </el-row> -->
      <!-- 审批意见 -->
      <div class="titel"></div>
      <el-form-item prop="auditSuggestion" class="textareaidea">
        <h3>审批意见</h3>
        <el-input
          type="textarea"
          autosize
          placeholder="请输入审批意见"
          v-model="billForm.auditSuggestion"
        >
        </el-input>
      </el-form-item>

      <!-- 提交 驳回 -->
      <el-form-item style="margin-top: 20px;margin-bottom: 5px;">
        <el-row type="flex" justify="center">
         <el-col :span="10" style="margin-right: 10px;">
        <el-button @click.prevent="submit('PASS')" class="subButton"
        v-loading.fullscreen.lock="fullscreenLoading" >通过</el-button>
        </el-col>
        <el-col :span="10" style="margin-left: 10px;">
        <el-button @click.prevent="submit('BACK')" class="cancelButton"
        v-loading.fullscreen.lock="fullscreenLoading" >驳回</el-button>
        </el-col>
      </el-row>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      billId: '',
      busiType: '',
      dialogVisible: false,
      fullscreenLoading: false, // 加载中图层蒙版显示
      billForm: {
        applyDate: '',
        billCode: '',
        billId: '',
        billMaker: '',
        leaveBData: [],
        leaveType: '',
        psnData: {},
        sumHour: '',
        billType: '',
        auditBy: '', // 审批人
        auditUserId: '', // 审批人id
        variable: '', // 审批是否通过
        auditSuggestion: ''// 审批意见
      },
      rules: { // 校验规则
        auditSuggestion: [
          { required: false, message: '请填写审批意见', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    // this.$route当前路由的相关信息(path/query/params)
    const { busiType, billId } = this.$route.params
    if (billId) {
      localStorage.setItem('billId', billId)
      localStorage.setItem('busiType', busiType)
      this.billId = billId
      this.busiType = busiType
    } else {
      this.billId = localStorage.getItem('billId')
      this.busiType = localStorage.getItem('busiType')
    }
    this.getOrderDetail(this.busiType, this.billId)
  },
  methods: {
    async getOrderDetail (busiType, billId) {
      // 从本地获取用户 id 和 token
      // 发送获取个人信息的请求时，需要携带上token令牌
      const userId = localStorage.getItem('user_id')
      // const res = await this.$axios.get(`/ApproveQryDetailService?busiType=${busiType}&billId=${billId}&userId=${userId}`)
      await this.$axios.get('/ApproveQryDetailService', {
        params: {
          busiType: busiType,
          billId: billId,
          userId: userId
        }
      }
      ).then((res) => {
        const { statusCode, data } = res.data
        if (statusCode === '200') {
          this.billForm = data.leave
          this.$set(this.billForm, 'variable', 'PASS')
        }
      })
        .catch((error) => {
          console.log(error)
        })
    },
    submit (variable) {
      this.$refs.billRef.validate(valid => {
        if (variable === 'BACK') {
          if (!this.billForm.auditSuggestion) {
            this.$toast.fail('请填写驳回意见')
            return
          }
        }
        this.billForm.variable = variable
        this.billForm.auditUserId = localStorage.getItem('user_id')
        this.billForm.auditBy = localStorage.getItem('user_name')
        this.billForm.billType = 'leave'
        this.fullscreenLoading = true // 提交时显示加载中蒙版
        this.$axios
          .post('/ApproveBillService', this.billForm)
          .then(res => {
            const { statusCode, msg } = res.data
            this.fullscreenLoading = false // 提交时完成后取消加载中蒙版
            if (statusCode === '200') {
              // 轻提示框
              this.$toast.success(msg)
              // 审批成功后，跳转到首页
              this.$router.push('/TdList')
            } else {
              // 否则轻提示登录信息有误
              this.$toast.fail(msg)
            }
          })
          .catch(eror => {
            this.fullscreenLoading = false
            this.$toast.fail('接口请求异常，请联系管理员：' + eror)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// 上传区域大框框
.front,
.back {
  width: 100%;
  height: 158px;
  margin-top: 50px;
  text-align: center;
  border: 1px solid #dce4ff;
  border-radius: 5px;
}
</style>
