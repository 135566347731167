<template>
  <div class="tdList">
    <my-header>薪资查询</my-header>
    <van-row style="margin-top: 20px;">
      <van-col span="2"/>
        <van-col span="7">
            <van-field v-model="beginDate" class="dpick" @click="showDateBg()" readonly />
        </van-col>
        <van-col span="2" class="vancol">
            <van-icon class-prefix="fa fa-caret-down" @click="showDateBg()"/>
        </van-col>
        <van-col span="2">
            <van-field class="vanfield" label="至" />
        </van-col>
        <van-col span="7">
            <van-field v-model="endDate" class="dpick" @click="showDateEd()" readonly />
        </van-col>
        <van-col span="2" class="vancol">
            <van-icon class-prefix="fa fa-caret-down" @click="showDateEd()"/>
        </van-col>
    </van-row>

    <van-popup v-model="isShowBegin" position="bottom" style="height: 50%" round>
          <van-datetime-picker v-model="currentDate" type="year-month" title="开始年月" :min-date="minDate" :max-date="maxDate"
            @cancel="isShowBegin = false" :show-toolbar="true" @confirm="confirmBg"/>
    </van-popup>
    <van-popup v-model="isShowEnd" position="bottom" style="height: 50%" round>
          <van-datetime-picker v-model="currentDate" type="year-month" title="结束年月" :min-date="minDate" :max-date="maxDate"
            @cancel="isShowBegin = false" :show-toolbar="true" @confirm="confirmEd"/>
    </van-popup>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list :error="error" error-text="未查询到该区间薪资信息"  @load="queryList">

        <van-row v-if="moneyYfTotal > 0">
        <van-col span="8"><van-cell :title="moneyYfTotal" class="yfcell" label="应发总计(元)"></van-cell></van-col>
        <van-col span="8"><van-cell :title="moneySfTotal" class="sfcell" label="实发总计(元)"></van-cell></van-col>
        <van-col span="8"><van-cell :title="moneyKkTotal" class="kkcell" label="扣款总计(元)"></van-cell></van-col>
        </van-row>

        <van-row v-for="(item,index) in lispaysliplist" :key="index" :title="item.period" @click="queryDetail(item.period,item.pk_wa_class)" class="tb-row">
        <van-col span="24">
          <van-cell span="12" :title="formatDate(item.period)" class="pdcell"><span class="pdspan">{{item.paySlipName}}</span></van-cell>
        </van-col>

        <van-col span="2"/>
        <van-col span="5.5"><van-cell :title="item.moneyYf" class="vcell" label="应发合计(元)"></van-cell></van-col>
        <van-col span="0.5"/>
        <van-col span="5.5"><van-cell :title="item.moneySf" class="vcell" label="实发合计(元)"></van-cell></van-col>
        <van-col span="0.5"/>
        <van-col span="5.5"><van-cell :title="item.moneyKk" class="vcell" label="扣款合计(元)"></van-cell></van-col>
        </van-row>

      </van-list>
    </van-pull-refresh>

    <!-- 底部 -->
    <div class="footer">
      <ul>
        <li>
          <div @click="$router.push({ name: 'Home' })">
            <i class="el-icon-s-home big"></i>
            <p>首页</p>
          </div>
        </li>
        <li>
          <div>
            <i class="el-icon-s-order current"></i>
            <p>待办</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'WorkBench' })">
            <i class="el-icon-s-platform"></i>
            <p>工作台</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'My' })">
            <i class="el-icon-s-custom"></i>
            <p>我的</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      isShowBegin: false, // 显示时间选择器
      isShowEnd: false, // 显示时间选择器
      currentDate: new Date(),
      minDate: new Date(1900, 0, 1), // 最小的可选的日期
      maxDate: new Date(), // 最大的可选日期
      beginDate: '', // 开始日期
      endDate: '', // 结束日期
      moneyKkTotal: 0,
      moneySfTotal: 0,
      moneyYfTotal: 0,
      psnData: {},
      lispaysliplist: [],
      error: false,
      refreshing: false
    }
  },
  created () {
    // 加载数据  默认上个月到当前月的查询
    this.beginDate = moment().startOf('year').format('YYYY年MM月')
    this.endDate = moment(new Date()).format('YYYY年MM月')
    this.queryList()
  },
  methods: {
    formatDate: function (value) {
      const year = value.slice(0, 4)
      const month = value.substring(4)
      return year + '年' + month + '月'
    },
    showDateBg () {
      // 时间选择器打开
      this.isShowBegin = true
      this.currentDate = new Date() // 设置data组件默认显示时间
    },
    showDateEd () {
      // 时间选择器打开
      this.isShowEnd = true
      this.currentDate = new Date() // 设置data组件默认显示时间
    },
    // 时间选择
    confirmBg () {
      this.beginDate = moment(this.currentDate).format('YYYY年MM月')
      this.queryList()
      // 时间选择器关闭
      this.isShowBegin = false
    },
    confirmEd () {
      this.endDate = moment(this.currentDate).format('YYYY年MM月')
      this.queryList()
      // 时间选择器关闭
      this.isShowEnd = false
    },
    async queryList () {
      const userId = localStorage.getItem('user_id')
      const beginDate = this.beginDate.replace(/年/, '').replace(/月/, '')
      const endDate = this.endDate.replace(/年/, '').replace(/月/, '')
      // 发送获取个人信息的请求时，需要携带上token令牌，需要放在请求头Authorization字段上
      await this.$axios
        .get('/CreQryPaySlipListService', {
          params: {
            userId: userId,
            beginDate: beginDate,
            endDate: endDate
          }
        })
        .then((res) => {
          const { data, msg, code } = res.data
          if (code === '1') {
            this.lispaysliplist = data.paysliplist
            this.psnData = data.psnData
            this.moneyKkTotal = data.moneyKkTotal
            this.moneySfTotal = data.moneySfTotal
            this.moneyYfTotal = data.moneyYfTotal
            // 加载状态结束
            this.refreshing = false
            this.error = false
          } else {
            this.lispaysliplist = []
            this.psnData = {}
            this.moneyKkTotal = 0
            this.moneySfTotal = 0
            this.moneyYfTotal = 0
            this.error = true
            this.$toast.fail(msg)
          }
        })
        .catch((error) => {
          this.error = true
          console.log(error)
        })
    },
    // 下拉刷新
    onRefresh () {
      this.refreshing = false
      this.queryList()
    },
    // 跳转到详情页
    queryDetail (period, pkwaclass) {
      this.$router.push({
        name: 'PayslipDetail',
        params: {
          period: period,
          pkwaclass: pkwaclass
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
   .vcell{
    .van-cell__title{
    text-align: center;
    font-family: 'myFont2';
    font-size: 16px;
    font-weight: 700;
    .van-cell__label{
      font-size: 10px;
      font-weight: 300;
    }
    }
   }
   .yfcell {
    .van-cell__title{
    text-align: center;
    font-family: 'myFont2';
    font-size: 16px;
    font-weight: 700;
    color:rgb(55, 168, 55);
    .van-cell__label{
      font-size: 10px;
      font-weight: 300;
    }
    }
   }
   .sfcell {
    .van-cell__title{
    text-align: center;
    font-family: 'myFont2';
    font-size: 16px;
    font-weight: 700;
    color:rgb(241, 213, 5);
    .van-cell__label{
      font-size: 10px;
      font-weight: 300;
    }
    }
   }
   .kkcell {
    .van-cell__title{
    text-align: center;
    font-family: 'myFont2';
    font-size: 16px;
    font-weight: 700;
    color:rgb(237, 86, 86);
    .van-cell__label{
      font-size: 10px;
      font-weight: 300;
    }
    }
   }
   .pdcell{
    .van-cell__title{
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    .van-cell__label{
      font-size: 10px;
      font-weight: 300;
    }
    }
   }
   .pdspan{
    color:black;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
   }
   .vancol{
    padding-top: 12px;
   }
   .dpick{
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    padding-right: 2px;
   }
  .vanfield {
    font-size:13px;
    font-weight:600;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
   ::v-deep .van-field__label{
      color:black;
    }
  }
</style>
