<template>
  <div class="workBench">
    <my-header>我的工作台</my-header>

    <van-collapse v-model="activeNames">
          <van-collapse-item :name="creRole.crmCrmRole.busiType" v-if="creRole.crmCrmRole!=null" class="vci">
            <div slot="title" style="font-size:18px;font-weight: 700">{{creRole.crmCrmRole.busiType}}</div>
            <van-grid :column-num="4">
              <van-grid-item v-for="item in creRole.crmCrmRole.roles" :key="item.rcode" :text="item.rname" @click="toCreateBill(item.rcode)" >
                  <img :src="require('../assets/apr_infochange.png')" v-if="item.rcode === 'infochange'"/>
                  <img :src="require('../assets/apr_emprequire.png')" v-if="item.rcode === 'emprequire'"/>
                  <p class="p">{{item.rname}}</p>
              </van-grid-item>
            </van-grid>
          </van-collapse-item>
      </van-collapse>

    <!-- 底部 -->
    <div class="footer">
      <ul>
        <li>
          <div @click="$router.push({ name: 'Home' })">
            <i class="el-icon-s-home big"></i>
            <p>首页</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'TdList' })">
            <i class="el-icon-s-order"></i>
            <p>待办</p>
          </div>
        </li>
        <li>
          <div>
            <i class="el-icon-s-platform current"></i>
            <p>工作台</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'My' })">
            <i class="el-icon-s-custom"></i>
            <p>我的</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      creRole: {},
      activeNames: [],
      isLoading: false
    }
  },
  created () {
    this.activeNames = ['CRM管理']
    // 从本地获取权限菜单
    this.creRole = JSON.parse(localStorage.getItem('creRole'))
    console.info(this.creRole)
  },
  methods: {
    toCreateBill (busiType) {
      // 跳转到填报页面,审批页面组件=业务类型+Form
      const pushName = busiType[0].toUpperCase() + busiType.substr(1) + 'Form'
      this.$router.push({
        name: pushName,
        params: { busiType: busiType }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.workBench {
  height: 100%;
  // 单个订单
  .wait1,
  .completed1,
  .completed2 {
    border-radius: 7px;
    padding: 10px;
    box-shadow: 2px 2px 10px rgba(121, 118, 116, 0.4);
    margin: 20px 20px;
  }
}
.waitState {
  font-size: 0.3rem;
}
</style>
