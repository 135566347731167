<template>
  <div class="my-header">
    <!-- 左箭头部分 -->
    <div class="left">
      <i
        class="fa fa-angle-left"
        aria-hidden="true"
        @click="$router.go(-1)"
      ></i>
    </div>
    <!-- 大标题 -->
    <div class="headerName">
      <!-- 如果传值就用传来的，没有就用默认值 -->
      <slot>大标题</slot>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    edit () {
      console.log('点击了编辑，做点什么')
    }
  }
}
</script>

<style lang="scss" scoped>
.my-header {
  background: -webkit-linear-gradient(
    right,
    rgb(93, 142, 218, 1),
    rgb(93, 142, 218, 1)
  );
  padding-top: 10px;
  height: 30px;
  line-height: 30px;
  display: flex;
  background-color: #fff;
  padding-bottom: 10px;
  letter-spacing: 1px;
  .left {
    i {
      display: block;
      line-height: 30px;
      font-size: 30px;
      width: 40px;
      height: 40px;
      color: #fff;
      text-align: center;
    }
  }
  .headerName {
    flex: 1;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    margin-right: 30px;
  }
}
</style>
