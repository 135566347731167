<template>
  <div class="tdList">
    <my-header>工作待办</my-header>
    <!-- Tab 标签栏---------------------------------------
          1. v-model="busiType" 绑定一个值, 指定当前激活的tab栏的下标, 下标0开始
          2. sticky 吸顶粘性布局
          3. animated 切换动画
          4. swipeable 滑动切换效果

          下拉刷新
          v-model 设置为 false，表示加载完成
     ----------------------------------------------------------->
    <div class="state-tabs">
      <van-tabs
        v-model="busiType"
        @click="handleClick"
        sticky
        animated
        swipeable
      >
        <!-- 待办任务 -->
        <van-tab title="全部" name="ALL">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.comsug"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('comsug', item.billId)" style="marginTop:10px;">
                <h4>客户投诉</h4>
                <span class="waitState">{{ item.crmStatusName }}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 投诉内容 -->
                <div class="row clearfix">
                  <span class="fl">投诉内容</span>
                  <em class="startTime fr">{{ item.problemDesc }}</em>
                </div>
                 <!-- 处置时间 -->
                <div class="row clearfix">
                  <span class="fl">处置时间</span>
                  <em class="startTime fr">{{ item.processorTime }}</em>
                </div>
                <!-- 处置结果 -->
                <div class="row clearfix">
                  <span class="fl">处置结果</span>
                  <em class="startTime fr">{{ item.processorResult }}</em>
                </div>
              </div>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.require"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('require', item.billId)" style="marginTop:10px;">
                <h4>客户建议</h4>
                <span class="waitState">{{ item.crmStatusName }}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 建议内容 -->
                <div class="row clearfix">
                  <span class="fl">建议内容</span>
                  <em class="startTime fr">{{ item.problemDesc }}</em>
                </div>
                 <!-- 处置时间 -->
                <div class="row clearfix">
                  <span class="fl">处置时间</span>
                  <em class="startTime fr">{{ item.processorTime }}</em>
                </div>
                <!-- 处置结果 -->
                <div class="row clearfix">
                  <span class="fl">处置结果</span>
                  <em class="startTime fr">{{ item.processorResult }}</em>
                </div>
              </div>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.repair"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('repair', item.billId)" style="marginTop:10px;">
                <h4>报事报修</h4>
                <span class="waitState">{{ item.crmStatusName }}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 维修部位 -->
                <div class="row clearfix">
                  <span class="fl">维修部位</span>
                  <em class="startTime fr">{{ item.repairPlace }}</em>
                </div>
                <!-- 维修内容 -->
                <div class="row clearfix">
                  <span class="fl">维修内容</span>
                  <em class="startTime fr">{{ item.repairNote }}</em>
                </div>
                <!-- 维修联系人 -->
                <div class="row clearfix">
                  <span class="fl">维修联系人</span>
                  <em class="startTime fr">{{ item.repairPsn }}</em>
                </div>
                <!-- 维修联系电话 -->
                <div class="row clearfix">
                  <span class="fl">维修联系电话</span>
                  <em class="startTime fr">{{ item.repairPhone }}</em>
                </div>
                 <!-- 派单人 -->
                 <div class="row clearfix">
                  <span class="fl">派单人</span>
                  <em class="startTime fr">{{ item.processorUser }}</em>
                </div>
                 <!-- 派单时间 -->
                <div class="row clearfix">
                  <span class="fl">派单时间</span>
                  <em class="startTime fr">{{ item.processorTime }}</em>
                </div>
                <!-- 派单说明 -->
                <div class="row clearfix">
                  <span class="fl">派单说明</span>
                  <em class="startTime fr">{{ item.processorResult }}</em>
                </div>
                <!-- 回访人 -->
                <div class="row clearfix">
                  <span class="fl">回访人</span>
                  <em class="startTime fr">{{ item.revisitUser }}</em>
                </div>
                 <!-- 回访时间 -->
                <div class="row clearfix">
                  <span class="fl">回访时间</span>
                  <em class="startTime fr">{{ item.revisitTime }}</em>
                </div>
                <!-- 回访说明 -->
                <div class="row clearfix">
                  <span class="fl">回访说明</span>
                  <em class="startTime fr">{{ item.revisitMemo }}</em>
                </div>
              </div>
            </div>

            <div
              class="wait wait1"
              v-for="item in listinfo.infochange"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('infochange', item.billId)" style="marginTop:10px;">
                <h4>信息变更</h4>
                <span class="waitState">详情</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 变更内容 -->
                <div class="row clearfix">
                  <span class="fl">变更内容</span>
                  <em class="startTime fr">{{ item.changeInfo }}</em>
                </div>
              </div>
            </div>

          </van-pull-refresh>
        </van-tab>

        <van-tab title="客户投诉" name="comsug" v-if="isAuth('comsug')">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.comsug"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('comsug', item.billId)" style="marginTop:10px;">
                <h4>客户投诉</h4>
                <span class="waitState">{{ item.crmStatusName }}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 投诉内容 -->
                <div class="row clearfix">
                  <span class="fl">投诉内容</span>
                  <em class="startTime fr">{{ item.problemDesc }}</em>
                </div>
                 <!-- 处置时间 -->
                <div class="row clearfix">
                  <span class="fl">处置时间</span>
                  <em class="startTime fr">{{ item.processorTime }}</em>
                </div>
                <!-- 处置结果 -->
                <div class="row clearfix">
                  <span class="fl">处置结果</span>
                  <em class="startTime fr">{{ item.processorResult }}</em>
                </div>
              </div>
            </div>
          </van-pull-refresh>
        </van-tab>

        <van-tab title="客户建议" name="require" v-if="isAuth('require')">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.require"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('require', item.billId)" style="marginTop:10px;">
                <h4>客户建议</h4>
                <span class="waitState">{{ item.crmStatusName }}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 建议内容 -->
                <div class="row clearfix">
                  <span class="fl">建议内容</span>
                  <em class="startTime fr">{{ item.problemDesc }}</em>
                </div>
                 <!-- 处置时间 -->
                <div class="row clearfix">
                  <span class="fl">处置时间</span>
                  <em class="startTime fr">{{ item.processorTime }}</em>
                </div>
                <!-- 处置结果 -->
                <div class="row clearfix">
                  <span class="fl">处置结果</span>
                  <em class="startTime fr">{{ item.processorResult }}</em>
                </div>
              </div>
            </div>
          </van-pull-refresh>
        </van-tab>

        <van-tab title="报事报修" name="repair" v-if="isAuth('repair')">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.repair"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('repair', item.billId)" style="marginTop:10px;">
                <h4>报事报修</h4>
                <span class="waitState">{{ item.crmStatusName }}</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 维修部位 -->
                <div class="row clearfix">
                  <span class="fl">维修部位</span>
                  <em class="startTime fr">{{ item.repairPlace }}</em>
                </div>
                <!-- 维修内容 -->
                <div class="row clearfix">
                  <span class="fl">维修内容</span>
                  <em class="startTime fr">{{ item.repairNote }}</em>
                </div>
                <!-- 维修联系人 -->
                <div class="row clearfix">
                  <span class="fl">维修联系人</span>
                  <em class="startTime fr">{{ item.repairPsn }}</em>
                </div>
                <!-- 维修联系电话 -->
                <div class="row clearfix">
                  <span class="fl">维修联系电话</span>
                  <em class="startTime fr">{{ item.repairPhone }}</em>
                </div>
                 <!-- 派单人 -->
                 <div class="row clearfix">
                  <span class="fl">派单人</span>
                  <em class="startTime fr">{{ item.processorUser }}</em>
                </div>
                 <!-- 派单时间 -->
                <div class="row clearfix">
                  <span class="fl">派单时间</span>
                  <em class="startTime fr">{{ item.processorTime }}</em>
                </div>
                <!-- 派单说明 -->
                <div class="row clearfix">
                  <span class="fl">派单说明</span>
                  <em class="startTime fr">{{ item.processorResult }}</em>
                </div>
                <!-- 回访人 -->
                <div class="row clearfix">
                  <span class="fl">回访人</span>
                  <em class="startTime fr">{{ item.revisitUser }}</em>
                </div>
                 <!-- 回访时间 -->
                <div class="row clearfix">
                  <span class="fl">回访时间</span>
                  <em class="startTime fr">{{ item.revisitTime }}</em>
                </div>
                <!-- 回访说明 -->
                <div class="row clearfix">
                  <span class="fl">回访说明</span>
                  <em class="startTime fr">{{ item.revisitMemo }}</em>
                </div>
              </div>
            </div>
          </van-pull-refresh>
        </van-tab>

        <van-tab title="信息变更" name="infochange" v-if="isAuth('infochange')">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh" style="padding-bottom: 50px;">
            <div
              class="wait wait1"
              v-for="item in listinfo.infochange"
              :key="item.billId"
            >
              <div class="title" @click="auditDetail('infochange', item.billId)" style="marginTop:10px;">
                <h4>信息变更</h4>
                <span class="waitState">详情</span>
              </div>
              <div class="details">
                <!-- 订单号 -->
                <div class="row clearfix">
                        <span class="fl">单据号</span>
                        <em class="num fr">{{ item.billCode }}</em>
                  </div>
                  <!-- 单据日期 -->
                  <div class="row clearfix">
                    <span class="fl">单据日期</span>
                    <em class="startTime fr">{{ item.applyDate }}</em>
                  </div>
                 <!-- 管理处 -->
                <div class="row clearfix">
                  <span class="fl">管理处</span>
                  <em class="startTime fr">{{ item.deptName }}</em>
                </div>
                <!-- 变更内容 -->
                <div class="row clearfix">
                  <span class="fl">变更内容</span>
                  <em class="startTime fr">{{ item.changeInfo }}</em>
                </div>
              </div>
            </div>
          </van-pull-refresh>
        </van-tab>

      </van-tabs>
    </div>

    <!-- 底部 -->
    <div class="footer">
      <ul>
        <li>
          <div @click="$router.push({ name: 'ShHome' })">
            <i class="el-icon-s-home big"></i>
            <p>首页</p>
          </div>
        </li>
        <li>
          <div>
            <i class="el-icon-s-order current"></i>
            <p>待办</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'ShWorkBench' })">
            <i class="el-icon-s-platform"></i>
            <p>工作台</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'ShMy' })">
            <i class="el-icon-s-custom"></i>
            <p>我的</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      listinfo: {
        require: [],
        comsug: [],
        repair: [],
        infochange: []
      },
      isLoading: false,
      fullscreenLoading: false, // 加载中图层蒙版显示
      aprRole: {},
      busiType: 'ALL' // 默认所有
    }
  },
  created () {
    // 根据权限加载页签
    if (localStorage.getItem('aprRole') !== 'undefined') {
      this.aprRole = JSON.parse(localStorage.getItem('aprRole'))
    }
    // 加载数据
    const { busiType } = this.$route.params
    if (busiType) {
      localStorage.setItem('busi_type', busiType)
      this.busiType = busiType
    } else {
      this.busiType = localStorage.getItem('busi_type')
    }
    this.queryList(this.busiType)
  },
  methods: {
    // 是否有权限
    isAuth (busiType) {
      let isRole = false
      if (this.aprRole.crmCrmRole) {
        this.aprRole.crmCrmRole.roles.find((item) => {
          if (busiType === item.rcode) {
            isRole = true
          }
        })
      }

      return isRole
    },
    // tabs切换
    handleClick () {
      localStorage.setItem('busi_type', this.busiType)
      this.queryList(this.busiType)
    },
    async queryList (busiType) {
      // 从本地获取用户 id 和 token
      const pkcrm = localStorage.getItem('pk_crm_wechat_trussup')
      // 发送获取个人信息的请求时，需要携带上token令牌，需要放在请求头Authorization字段上
      await this.$axios
        .get('/AppCrmQryListService', {
          params: {
            pk_crm_wechat_trussup: pkcrm,
            busiType: busiType
          }
        })
        .then((res) => {
          const { statusCode, data } = res.data
          if (statusCode === '200') {
            this.listinfo = data
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    auditDetail (busiType, billId) {
      if (busiType === 'infochange') {
        const pushName = 'Sh' + busiType[0].toUpperCase() + busiType.substr(1) + 'Detail'
        this.$router.push({
          name: pushName,
          params: {
            busiType: busiType,
            billId: billId
          }
        })
      } else {
      // 调整到审批页面,审批页面组件=业务类型+Audit
        const pushName = 'Sh' + busiType[0].toUpperCase() + busiType.substr(1) + 'Eval'
        this.$router.push({
          name: pushName,
          params: {
            busiType: busiType,
            billId: billId
          }
        })
      }
    },
    // 下拉刷新
    onRefresh () {
      setTimeout(() => {
        this.$toast('刷新成功')
        this.isLoading = false
        this.queryList(this.busiType)
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.tdList {
  height: 100%;
  // 单个订单
  .wait1,
  .completed1,
  .completed2 {
    border-radius: 7px;
    margin-top: 10px;
    padding: 10px;
    box-shadow: 2px 2px 10px rgba(121, 118, 116, 0.4);
    margin: 10px;
    // background-color: yellow;
  }
}
.waitState {
  font-size: 0.3rem;
}

.allselectBgc {
  height: 100px;
  padding: 20px 10px;
  .darkBtn {
    float: right;
    background-color: rgb(245, 238, 238);
    border: none;
    color: gray;
    border-radius: 20px;
    padding: 10px 20px;
    height: 30px;
  }
  .brightBtn {
    float: right;
    background:rgb(93,142,218);
    color:white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    height: 30px;
  }
}
</style>
