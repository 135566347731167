<template>
  <div class="login">
    <!-- 登录表单 -->
    <el-form
      :model="loginForm"
      status-icon
      :rules="rules"
      ref="loginForm"
      label-position="left"
      label-width="0px"
      class="loginForm"
    >
    <el-form-item>
       <h3 class="logintitle">系统登录</h3>
    </el-form-item>
      <!-- 登录名 -->
      <el-form-item prop="loginName" style="margin-top:20px">
        <el-input
          v-model="loginForm.loginName"
          placeholder="用户名"
        ></el-input>
      </el-form-item>
      <!--密码 -->
      <el-form-item prop="password" style="margin-top:20px">
        <el-input
          type="password"
          v-model="loginForm.password"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <!-- 登录 -->
      <!-- <button @click="login" class="loginbtn">   -->
          <!-- Vue中使用form表单提交刷新问题：在@click后面跟上prevent 这样就可以阻止form表单默认刷新行为了-->
      <el-form-item>
          <button v-if="isWXFlag=='N'" type="submit" class="loginbtn" v-loading.fullscreen.lock="fullscreenLoading" @click.prevent='login'>
            <span>登录</span>
          </button>
          <button v-if="isWXFlag=='Y'" type="submit" class="loginbtn" v-loading.fullscreen.lock="fullscreenLoading" @click.prevent='WXLogin'>
            <span>登录</span>
          </button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fullscreenLoading: false, // 加载中图层蒙版显示
      billId: '',
      busiType: '',
      isWXFlag: 'N',
      isRelation: 'N',
      loginForm: {
        loginName: '',
        password: '',
        userType: '1'
      },
      userinfo: '',
      logininfo: '',
      rules: {
        loginName: [
          {
            required: true,
            message: '请输入账号',
            trigger: ['blur', 'change']
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  // 得到用户输入的登录信息
  created () {
    if (this.$tool.isWeChat()) { // 判断是否微信中打开
      this.isWXFlag = 'Y'
      this.billId = this.getQueryVariable('billId')
      console.log('获取消息链接中的billId:' + this.billId) // 获取消息链接中的billId参数
      this.auth()
    } else {
      this.billId = this.getQueryVariable('billId')
      console.log('PC端打开链接中的billId:' + this.billId) // 获取消息链接中的billId参数
    }
  },
  methods: {
    // 2021-8-5 登录前询问用户是否绑定账户
    WXLogin () {
      this.$confirm('是否绑定此账户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.isRelation = 'Y'
        this.login()
      }).catch(() => {
        this.isRelation = 'N'
        this.login()
      })
    },
    // 登录
    async login () {
      this.fullscreenLoading = true // 显示加载中蒙版
      // 以下请求地址已配置了基准路径，见main.js
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$axios.post('/LoginService', {
            loginName: this.loginForm.loginName,
            password: this.loginForm.password,
            isRelation: this.isRelation,
            openid: this.userinfo.openid,
            nickname: this.userinfo.nickname,
            sex: this.userinfo.sex,
            province: this.userinfo.province,
            city: this.userinfo.city
          }).then(res => {
            this.fullscreenLoading = false // 关闭加载中蒙版
            const { statusCode, token, data, msg } = res.data
            if (statusCode === '200') {
              console.info(data)
              // 登录状态为成功时，存储个人信息token到本地
              localStorage.setItem('dept_name', data.deptName)
              localStorage.setItem('dept_id', data.deptId)
              localStorage.setItem('login_name', data.loginName)
              localStorage.setItem('org_name', data.orgName)
              localStorage.setItem('password', data.password)
              localStorage.setItem('pk_psndoc', data.pk_psndoc)
              localStorage.setItem('post_name', data.postName)
              localStorage.setItem('psn_code', data.psnCode)
              localStorage.setItem('psn_name', data.psnName)
              localStorage.setItem('user_id', data.userId)
              localStorage.setItem('user_name', data.userName)
              localStorage.setItem('user_type', data.userType)
              localStorage.setItem('token', token)
              // 轻提示框
              this.$toast.success('登录成功')
              // 登录成功后，跳转到首页
              this.$router.push('/home')
            } else {
              // 否则轻提示登录信息有误
              this.$toast.fail(msg)
            }
          }).catch(eror => {
            this.fullscreenLoading = false
            this.$toast.fail('登录异常，请联系管理员：' + eror)
          })
        } else {
          this.fullscreenLoading = false
          return false
        }
      })
    },
    async auth () {
      this.fullscreenLoading = true // 提交时显示加载中蒙版
      var code = this.getQueryVariable('code')
      if (code === '') { // 如果url中没有code，则调用微信授权界面
        // 声明授权后要跳转的方法链接
        var redirectUrl = encodeURIComponent(window.location.href.split('#')[0])
        console.log(redirectUrl)
        // 引导用户进入授权界面
        var url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.$appid + '&redirect_uri=' + redirectUrl + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
        location.href = url
      } else { // 如果有code，则调用后台接口，获取用户登录信息
        // const res = await this.$axios.get(`/WXAuthService?code=${code}`)
        await this.$axios.get('/WXAuthService', {
          params: {
            code: code
          }
        }
        ).then((res) => {
          const { statusCode, token, data } = res.data
          this.fullscreenLoading = false // 关闭加载蒙版
          if (statusCode === '200') {
            // 登录状态为成功时，存储个人信息token到本地
            localStorage.setItem('dept_name', data.deptName)
            localStorage.setItem('dept_id', data.deptId)
            localStorage.setItem('login_name', data.loginName)
            localStorage.setItem('nick_name', data.nickname)
            localStorage.setItem('open_id', data.openid)
            localStorage.setItem('org_name', data.orgName)
            localStorage.setItem('password', data.password)
            localStorage.setItem('pk_psndoc', data.pk_psndoc)
            localStorage.setItem('post_name', data.postName)
            localStorage.setItem('psn_code', data.psnCode)
            localStorage.setItem('psn_name', data.psnName)
            localStorage.setItem('user_id', data.userId)
            localStorage.setItem('user_name', data.userName)
            localStorage.setItem('user_type', data.userType)
            localStorage.setItem('token', token)
            // 轻提示框
            this.$toast.success(res.msg)
            // 登录成功后，跳转到首页
            this.$router.push('/home')
          } else if (statusCode === '401') {
            this.fullscreenLoading = false // 关闭加载蒙版
            // 未绑定，保存授权用户openid
            localStorage.setItem('nick_name', data.nickname)
            localStorage.setItem('open_id', data.openid)
            localStorage.setItem('city', data.city)
            localStorage.setItem('province', data.province)
            localStorage.setItem('sex', data.sex)
            this.userinfo = {
              openid: data.openid,
              nickname: data.nickname,
              sex: data.sex,
              province: data.province,
              city: data.city
            }
            this.$toast.success(res.msg)
          }
        })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    // 截取url中的参数
    getQueryVariable (variable) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return ('')
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  // background-color: rgb(224, 168, 168);
  .el-form {
    padding: 0;
  }
  .loginForm {
    // background-color: rgb(211, 148, 148);
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin: 250px auto;
    width: 350px;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
    .loginbtn {
      width: 100%;
      height: 44px;
      margin-top: 30px;
      color: white;
      border: none;
      border-radius: 5px;
      background-color: #8bb2ff;
      span {
        margin-left: 20px;
        // background-color: red;
        letter-spacing: 20px;
      }
    }
    .logintitle{
      font-size: 18px;
      text-align: center;
      padding: 12px;
      color: #666;
      }
    .forgetPassword {
      font-size: 12px;
      margin-left: 260px;
      color: #999;
    }
  }
  .agree {
    position: absolute;
    bottom: 30px;
    left: 66px;
    span {
      font-size: 12px;
      font-family: myFont;
      color: #9999;
      line-height: 24px;
      margin-right: 10px;
    }
    a {
      font-size: 12px;
      font-family: myFont;
      color: #3578fb;
      line-height: 24px;
    }
  }
  .centered-link {
  color: black;
  margin-top: 10px;
  font-size: 14px;
  }
}
</style>
