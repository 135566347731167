<template>
  <div class="my">
    <my-header>我的</my-header>

    <!-- 表单内容 -->
    <el-form
      :model="billForm"
      ref="billRef"
      label-width="100px"
      class="billForm"
    >
      <h1>注销账号</h1>
      <p>请确认以下操作：</p>
      <ul>
      <li>您的账号将被永久注销，无法恢复。</li>
      <li>您将失去账号相关的所有信息和数据。</li>
      <li>请确保已备份好重要数据。</li>
      </ul>
      <el-form-item>
        <el-button
          @click.prevent="submit()"
          class="cancelButton"
          style="margin-top: 20px;background-color:red"
          v-loading.fullscreen.lock="fullscreenLoading"
          >确认注销</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fullscreenLoading: false, // 加载中图层蒙版显示
      billForm: {}
    }
  },
  methods: {
    logOut (pkCrm) {
      // 发送注销请求
      this.$axios
        .post('/CrmSignoutService?pk_crm_wechat_trussup=' + pkCrm)
        .then((res) => {
          const { statusCode, msg } = res.data
          this.fullscreenLoading = false // 提交时完成后取消加载中蒙版
          if (statusCode === '200') {
            localStorage.removeItem('address')
            localStorage.removeItem('city')
            localStorage.removeItem('custName')
            localStorage.removeItem('custType')
            localStorage.removeItem('deptCode')
            localStorage.removeItem('deptName')
            localStorage.removeItem('name')
            localStorage.removeItem('nickname')
            localStorage.removeItem('openid')
            localStorage.removeItem('phone')
            localStorage.removeItem('pk_crm_wechat_trussup')
            localStorage.removeItem('pk_dept')
            localStorage.removeItem('province')
            localStorage.removeItem('sex')
            localStorage.removeItem('token')
            // 轻提示框
            this.$toast.success('注销完成')
            // 登出成功后，跳转到登录页
            this.$router.push('/shLogin')
          } else {
            // 否则轻提示登录信息有误
            this.$toast.fail(msg)
          }
        })
        .catch((eror) => {
          this.fullscreenLoading = false
          this.$toast.fail('接口请求异常，请联系管理员：' + eror)
        })
    },
    submit () {
      const pkCrm = localStorage.getItem('pk_crm_wechat_trussup')
      if (pkCrm) {
        this.$confirm('确认要注销账号吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          this.logOut(pkCrm)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my {
  .theOther {
    margin-bottom: 20px;
  }
  #bn {
    border-bottom: none;
  }
  #welcome {
    margin-top: 40px;
  }
}

h1 {
  margin-left: 5px;
  font-size: 24px;
  margin-bottom: 16px;
}

p {
  font-size: 18px;
  margin-left: 5px;
  margin-bottom: 8px;
}

ul {
  margin-bottom: 32px;
  list-style-type: disc;
  padding-left: 24px;
}

li {
  margin-bottom: 8px;
}
</style>
