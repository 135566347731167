<template>
  <div class="my">
    <my-header>我的</my-header>

    <!-- 用户信息 -->
    <div class="titel"></div>
    <div class="myInfo">
      <my-nav name="当前账号" :desc="loginName"></my-nav>
      <my-nav name="所属公司" :desc="orgName"></my-nav>
      <my-nav name="所属部门" :desc="deptName"></my-nav>
      <my-nav name="岗位" :desc="postName"></my-nav>
      <my-nav name="员工号" :desc="psnCode"></my-nav>
      <my-nav name="用户名" :desc="userName"></my-nav>
      <!-- <my-nav name="公司"  :desc="orgName"></my-nav> -->
    </div>
    <div class="titel"></div>
    <div class="theOther">
      <!-- <my-nav name="关于我们" id="bn"></my-nav> -->
      <my-nav name="退出" id="loginOut" @click='loginOut'></my-nav>
    </div>
    <div class="titel"></div>

    <div id="welcome">
    </div>

    <!-- 底部 -->
    <div class="footer">
      <ul>
        <li>
          <div @click="$router.push({ name: 'Home'})">
            <i class="el-icon-s-home big"></i>
            <p>首页</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'TdList'})">
            <i class="el-icon-s-order"></i>
            <p>待办</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'WorkBench'})">
            <i class="el-icon-s-platform"></i>
            <p>工作台</p>
          </div>
        </li>
        <li>
          <div>
            <i class="el-icon-s-custom current"></i>
            <p>我的</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      orgName: '',
      deptName: '',
      postName: '',
      loginName: '',
      userName: '',
      psnCode: ''
    }
  },
  created () {
    this.orgName = localStorage.getItem('org_name')
    this.deptName = localStorage.getItem('dept_name')
    this.loginName = localStorage.getItem('login_name')
    this.userName = localStorage.getItem('user_name')
    this.postName = localStorage.getItem('post_name')
    this.psnCode = localStorage.getItem('psn_code')
  },
  methods: {
    // 登出
    async loginOut () {
      localStorage.removeItem('dept_name')
      localStorage.removeItem('login_name')
      localStorage.removeItem('nick_name')
      localStorage.removeItem('open_id')
      localStorage.removeItem('org_name')
      localStorage.removeItem('password')
      localStorage.removeItem('pk_psndoc')
      localStorage.removeItem('post_name')
      localStorage.removeItem('psn_code')
      localStorage.removeItem('psn_name')
      localStorage.removeItem('user_id')
      localStorage.removeItem('user_name')
      localStorage.removeItem('user_type')
      localStorage.removeItem('token')
      // 轻提示框
      this.$toast.success('登出成功')
      // 登出成功后，跳转到登录页
      this.$router.push('/Login')
    }
  }
}
</script>

<style lang="scss" scoped>
.my {
  .theOther {
    margin-bottom: 20px;
  }
  #bn {
    border-bottom: none;
  }
  #welcome {
    margin-top: 40px;
  }
}
</style>
