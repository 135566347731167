<template>
  <div class="login">
    <!-- 登录表单 -->
    <el-form :model="loginForm" status-icon :rules="rules" ref="loginForm" label-position="left" label-width="0px"
      class="loginForm">
      <el-form-item>
        <img src="" />
      </el-form-item>
      <!-- 扫码登记 -->
      <el-form-item>
        <button type="submit" class="loginbtn" v-loading.fullscreen.lock="fullscreenLoading"
          @click.prevent='userRegister'>
          <span>扫码登记</span>
        </button>
      </el-form-item>
      <el-form-item prop="custType">
              <div class="custType">
                <el-radio v-model="loginForm.custType" label="CUST">客户</el-radio>
                <el-radio v-model="loginForm.custType" label="SUP">供应商</el-radio>
              </div>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fullscreenLoading: false, // 加载中图层蒙版显示
      loginForm: {
        custType: 'CUST'
      },
      userinfo: '',
      rules: {}
    }
  },
  // 得到用户输入的登录信息
  created () {
    if (this.$tool.isWeChat()) { // 判断是否微信中打开
      this.auth()
    }
    // 开发测试入口,token过期，需手工获取替换
    if (!this.$tool.isWeChat()) {
      localStorage.setItem('pk_crm_wechat_trussup', '0001A11000000000X9Y1')
      localStorage.setItem('deptName', '医药学校管理处')
      localStorage.setItem('name', '0001A11000000000X9Y1')
      localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDM3MDE3NDQsInVzZXJuYW1lIjoiMTAyMDQ5IiwicGFzc3dvcmQiOiJRV0VyIUAjNCJ9.VBAIumNQUTqf7ojO8dPQ7__tx_UkiIb9UJJZ7L-Ra3M')
      // 轻提示框
      this.$toast.success('开发入口')
      // 登录成功后，跳转到自助首页
      this.$router.push('/shWorkBench')
    }
  },
  methods: {
    // 2023-10-09 用户注册申请
    userRegister () {
      var url = encodeURIComponent(window.location.href.split('#')[0])
      if (this.loginForm.custType === 'CUST') {
        this.$router.push({
          name: 'CustRegister',
          params: {
            url: url
          }
        })
      } else if (this.loginForm.custType === 'SUP') {
        this.$router.push({
          name: 'SupRegister'
        })
      }
    },
    async auth () {
      this.fullscreenLoading = true // 提交时显示加载中蒙版
      var code = this.getQueryVariable('code')
      if (code === '') { // 如果url中没有code，则调用微信授权界面
        // 声明授权后要跳转的方法链接
        var redirectUrl = encodeURIComponent(window.location.href.split('#')[0])
        // 引导用户进入授权界面
        var url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.$appid + '&redirect_uri=' + redirectUrl + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
        location.href = url
      } else { // 如果有code，则调用后台接口，获取用户登录信息
        await this.$axios.get('/CrmWXAuthService', {
          params: {
            code: code
          }
        }
        ).then((res) => {
          const { statusCode, token, data } = res.data
          this.fullscreenLoading = false // 关闭加载蒙版
          if (statusCode === '200') {
            // 登录状态为成功时，存储个人信息token到本地
            localStorage.setItem('address', data.address)
            localStorage.setItem('city', data.city)
            localStorage.setItem('custName', data.custName)
            localStorage.setItem('custType', data.custType)
            localStorage.setItem('deptCode', data.deptCode)
            localStorage.setItem('deptName', data.deptName)
            localStorage.setItem('name', data.name)
            localStorage.setItem('nickname', data.nickname)
            localStorage.setItem('openid', data.openid)
            localStorage.setItem('phone', data.phone)
            localStorage.setItem('pk_crm_wechat_trussup', data.pk_crm_wechat_trussup)
            localStorage.setItem('pk_dept', data.pk_dept)
            localStorage.setItem('province', data.province)
            localStorage.setItem('sex', data.sex)
            localStorage.setItem('token', token)
            // 轻提示框
            this.$toast.success(res.msg)
            // 登录成功后，跳转到工作台
            this.$router.push('/shWorkBench')
          } else if (statusCode === '401') {
            this.fullscreenLoading = false // 关闭加载蒙版
            // 未绑定，保存授权用户openid
            localStorage.setItem('custType', this.loginForm.custType)
            localStorage.setItem('nick_name', data.nickname)
            localStorage.setItem('openid', data.openid)
            localStorage.setItem('city', data.city)
            localStorage.setItem('province', data.province)
            localStorage.setItem('sex', data.sex)
            this.userinfo = {
              openid: data.openid,
              nickname: data.nickname,
              sex: data.sex,
              province: data.province,
              city: data.city
            }
            this.$toast.success(res.msg)
          }
        })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    // 截取url中的参数
    getQueryVariable (variable) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return ('')
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;

  // background-color: rgb(224, 168, 168);
  .el-form {
    padding: 0;
  }

  .loginForm {
    // background-color: rgb(211, 148, 148);
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin: 250px auto;
    width: 350px;

    .loginbtn {
      width: 100%;
      height: 44px;
      margin-top: 30px;
      color: white;
      border: none;
      border-radius: 5px;
      background-color: #8bb2ff;

      span {
        margin-left: 20px;
        // background-color: red;
        letter-spacing: 20px;
      }
    }
    .custType{
      margin-top: 30px;
      text-align:center
    }
  }

}
</style>
