<template>
  <div class="tdList">
    <my-header>薪资查询</my-header>
    <van-row style="margin-top: 20px;">
      <van-col span="24"><van-cell :title="formatDate(period)" class="hcell"></van-cell></van-col>
      <van-col span="8"><van-cell :title="moneyYf" class="vcell" label="应发合计(元)"></van-cell></van-col>
      <van-col span="8"><van-cell :title="moneySf" class="vcell" label="实发合计(元)"></van-cell></van-col>
      <van-col span="8"><van-cell :title="moneyKk" class="vcell" label="扣款合计(元)"></van-cell></van-col>
    </van-row>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" >
      <van-list :error="error" error-text="未查询到该区间薪资信息"  @load="onRefresh()" >
        <van-row class="tb-person">
          <van-col span="24"><van-cell-group><van-cell title="姓名" class="pdcell" :value="psnData.psnName"/></van-cell-group></van-col>
        </van-row>
        <van-row v-for="item in payslipdetail" :key="item.itemName" >
          <van-col span="24">
            <van-cell-group><van-cell :title="item.itemName" class="pdcell" :value="item.itemValue"></van-cell></van-cell-group>
          </van-col>
        </van-row>
      </van-list>
    </van-pull-refresh>

    <!-- 底部 -->
    <div class="footer">
      <ul>
        <li>
          <div @click="$router.push({ name: 'Home' })">
            <i class="el-icon-s-home big"></i>
            <p>首页</p>
          </div>
        </li>
        <li>
          <div>
            <i class="el-icon-s-order current"></i>
            <p>待办</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'WorkBench' })">
            <i class="el-icon-s-platform"></i>
            <p>工作台</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'My' })">
            <i class="el-icon-s-custom"></i>
            <p>我的</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      pkwaclass: '',
      period: '',
      moneyKk: '',
      moneySf: '',
      moneyYf: '',
      psnData: {},
      payslipdetail: [],
      error: false,
      refreshing: false
    }
  },
  created () {
    // 加载数据
    const { period, pkwaclass } = this.$route.params
    if (pkwaclass) {
      localStorage.setItem('period', period)
      localStorage.setItem('pkwaclass', pkwaclass)
      this.period = period
      this.pkwaclass = pkwaclass
    } else {
      this.period = localStorage.getItem('period')
      this.pkwaclass = localStorage.getItem('pkwaclass')
    }
    this.queryDetail(this.period, this.pkwaclass)
  },
  methods: {
    formatDate: function (value) {
      const year = value.slice(0, 4)
      const month = value.substring(4)
      return year + '年' + month + '月'
    },
    async queryDetail (period, pkwaclass) {
      const userId = localStorage.getItem('user_id')
      // 发送获取个人信息的请求时，需要携带上token令牌，需要放在请求头Authorization字段上
      await this.$axios
        .get('/CreQryPaySlipDetailService', {
          params: {
            userId: userId,
            period: period,
            pk_wa_class: pkwaclass
          }
        })
        .then((res) => {
          const { statusCode, data, msg } = res.data
          if (statusCode === '200') {
            this.payslipdetail = data.payslipdetail
            this.psnData = data.psnData
            this.period = data.period
            this.moneyKk = data.moneyKk
            this.moneySf = data.moneySf
            this.moneyYf = data.moneyYf
            if (this.payslipdetail.length === 0) {
              this.error = true
            }
            // 加载状态结束
            this.refreshing = false
            this.error = false
          } else {
            this.error = true
            this.$toast.fail(msg)
          }
        })
        .catch((error) => {
          this.error = true
          console.log(error)
        })
    },
    // 下拉刷新
    onRefresh () {
      this.refreshing = false
      this.period = localStorage.getItem('period')
      this.pkwaclass = localStorage.getItem('pkwaclass')
      this.queryDetail(this.period, this.pkwaclass)
    }
  }
}
</script>

<style lang="scss" scoped>
   .th-row{
     height: 56px;
     line-height: 56px;
     background: #EBF1F7;
     padding: 0px 12px;
   }
   .tb-row{
     line-height: 35px;
     padding: 0px 12px;
     background-color: #EBF1F7;
   }
   .hcell{
    .van-cell__title{
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    }
   }
   .vcell{
    .van-cell__title{
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    }
   }
   .pdcell{
    .van-cell__title{
    font-size: 14px;
    font-weight: 700;
    }
    .van-cell__value {
      text-align: right;
      font-size: 14px;
      font-weight: 700;
      color: #000;
    }
   }
   .dpick{
    text-align: right;
    font-size: 14px;
    font-weight: 700;
   }

</style>
