<template>
  <div>
    <my-header>附件查看</my-header>
    <div class="m-pdf">
      <div id="pdf"></div>
    </div>
  </div>
</template>
<script>
// import Vconsole from 'vconsole' // 实例化
import Pdfh5 from 'pdfh5'
import 'pdfh5/css/pdfh5.css'
export default {
  data () {
    return {
      pdfUrl: '',
      pdfh5: null
    }
  },
  created () {
    /* var vc = new Vconsole()
    window.onerror = function (errorMessage, scriptURI, lineNumber, columnNumber, errorObj) {
      console.log('错误信息：', errorMessage)
      console.log('出错文件：', scriptURI)
      console.log('出错行号：', lineNumber)
      console.log('出错列号：', columnNumber)
      console.log('错误详情：', errorObj)
    }
    vc.show()
    console.log('[system]', 'foo') // 'foo' 会输出到 System 面板
    console.log('[system] bar') // 这行日志会输出到 Log 面板而非 System 面板 */
    const { url } = this.$route.params
    console.log(url)
    if (url) {
      this.pdfUrl = url.replace(/http:\/\/ydl.ybanyun.com/, 'fsApi')
      localStorage.setItem('pdfUrl', this.pdfUrl)
    } else {
      this.pdfUrl = localStorage.getItem('pdfUrl')
    }
    console.log(this.pdfUrl)
  },
  mounted () {
    // 先实例化
    /*  try {
      this.pdfh5 = new Pdfh5('#pdf', {
        pdfurl: this.pdfUrl,
        lazy: true
      })
      this.pdfh5.on('complete', function (status, msg, time) {
        console.log('状态：' + status + '，信息：' + msg + '，耗时：' + time + '毫秒，总页数：' + this.totalNum)
      })
      // 你的逻辑代码
    } catch (error) {
      // 错误处理代码片段
      console.log(error)
    } */
    // 跨域代理 proxyTable
    this.$axios
      .get(this.pdfUrl, {
        responseType: 'arraybuffer'
      })
      .then(res => {
        this.pdfh5 = new Pdfh5('#pdf', {
          data: res.data
        })
      })
  }

}
</script>

<style lang="scss" scoped>
*{
padding: 0;
margin: 0;
}
html,body,#m-pdf {
width: 100%;
height: 100%;
}
</style>
