<template>
  <div class="billForm">
    <my-header>报事报修</my-header>

    <!-- 表单内容 -->
    <el-form :model="billForm" name="billForm" :rules="rules" ref="billRef" label-width="100px" class="billForm">
      <el-row class="form-row">
        <el-col :span="5">
          <el-form-item class="form-item-title">
            <div class="bar"></div>
            <h3>管理处:</h3>
          </el-form-item>
        </el-col>
        <el-col :span="19">
          <el-form-item prop="deptName">
            <el-input type="text" v-model="deptName" readonly>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-row">
         <el-col :span="7">
            <el-form-item class="form-item-title">
               <div class="bar"></div>
               <h3><span style="color:red">*</span>维修部位:</h3>
            </el-form-item>
         </el-col>
         <el-col :span="17" >
           <el-form-item prop="repairPlace">
            <el-input type="text" v-model="billForm.repairPlace" ></el-input>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-row">
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3><span style="color:red">*</span>维修内容:</h3>
            </el-form-item>
         </el-col>
      </el-row>
      <el-row>
         <el-col :span="24" >
           <el-form-item prop="repairNote">
            <el-input type="textarea" v-model="billForm.repairNote" :maxlength="500" :autosize="{ minRows: 5, maxRows: 10}"></el-input>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-row">
         <el-col :span="8">
            <el-form-item class="form-item-title">
               <div class="bar"></div>
               <h3><span style="color:red">*</span>维修联系人:</h3>
            </el-form-item>
         </el-col>
         <el-col :span="16" >
           <el-form-item prop="repairPsn">
            <el-input type="text" v-model="billForm.repairPsn" ></el-input>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-row">
         <el-col :span="8">
            <el-form-item class="form-item-title">
               <div class="bar"></div>
               <h3><span style="color:red">*</span>维修联系电话:</h3>
            </el-form-item>
         </el-col>
         <el-col :span="16" >
           <el-form-item prop="repairPhone">
            <el-input type="text" v-model="billForm.repairPhone" ></el-input>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-row">
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3>图片上传:</h3>
            </el-form-item>
         </el-col>
         <el-col :span="15" >
          <el-upload action="uploadAction" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
            :limit="3" :show-file-list="true" name="img" ref="upload" :data="billForm" accept="image/png,image/gif,image/jpg,image/jpeg"
            :headers="headers" :on-exceed="handleExceed" :auto-upload="false" :on-error="uploadError" :before-upload="handleBeforeUpload"
            :on-change="changeFile">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog width="400px" :visible.sync="dialogVisible">
          <img width="350px" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-col>
      </el-row>

      <!-- 提交 -->
      <el-form-item class="submitButton">
        <el-button @click.prevent="submit()" class="subButton"
          v-loading.fullscreen.lock="fullscreenLoading">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  data () {
    return {
      deptName: '',
      busiType: '',
      dialogImageUrl: '', // 图片回显
      uploadFiles: [], // formData img 文件
      dialogFormVisible: '',
      disabled: false,
      dialogVisible: false,
      fullscreenLoading: false, // 加载中图层蒙版显示
      billForm: {
        crmBusiType: '',
        repairPlace: '',
        repairNote: '',
        repairPsn: '',
        repairPhone: '',
        pk_crm_wechat_trussup: ''
      },
      rules: { // 校验规则
        repairPlace: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        repairNote: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        repairPsn: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        repairPhone: [
          { required: true, message: '必填', trigger: 'blur' }
        ]
      },
      headers: {
        token: localStorage.getItem('token')
      }
    }
  },
  mounted () {

  },
  created () {
    const { busiType } = this.$route.params
    if (busiType) {
      localStorage.setItem('busiType', busiType)
      this.busiType = busiType
    } else {
      this.busiType = localStorage.getItem('busiType')
    }
    this.deptName = localStorage.getItem('deptName')
  },
  methods: {
    // 上传文件之前的钩子
    handleBeforeUpload (file) {
      console.log('按钮', this.titleMap)
      if (!(file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg')) {
        this.$toast.warning({
          title: '警告',
          message: '请上传格式为image/png, image/gif, image/jpg, image/jpeg的图片'
        })
      }
      const size = file.size / 1024 / 1024 / 2
      if (size > 2) {
        this.$toast.warning({
          title: '警告',
          message: '图片大小必须小于2M'
        })
      }
    },
    // 图片上传超过数量限制
    handleExceed (files, fileList) {
      this.$toast.fail('上传图片不能超过1张!')
    },
    handleRemove (file, fileList) {
      this.$toast.fail('删除成功!')
    },
    // 图片上传失败时
    uploadError () {
      this.$toast.fail('图片上传失败')
    },
    // 预览图片
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 文件改变时 on-change方法 ，fileList[0].raw 就是传给后端的值
    // filelist这个对象里面有很多属性，我们上传文件时，实际上传的是filelist列表中每一项的raw,只有raw可以正常上传， 获取到文件后，需要定义变量保存文件。所以需要获取filelist中的raw进行保存。
    // 这里我用的formdata上传多文件，console打印formdata，文件在控制台中显示的格式为binary。
    changeFile (file, fileList) {
      this.uploadFiles = fileList
    },
    submit () {
      this.$refs.billRef.validate(valid => {
        if (valid) {
          const fd = new FormData()
          fd.append('pk_crm_wechat_trussup', localStorage.getItem('pk_crm_wechat_trussup'))
          fd.append('crmBusiType', this.busiType.toUpperCase())
          fd.append('repairPlace', this.billForm.repairPlace)
          fd.append('repairNote', this.billForm.repairNote)
          fd.append('repairPsn', this.billForm.repairPsn)
          fd.append('repairPhone', this.billForm.repairPhone)
          this.uploadFiles.forEach(file => {
            fd.append('pic', file.raw) // 文件
          })
          this.fullscreenLoading = true // 提交时显示加载中蒙版
          this.$axios
            .post('/AppCrmRepairService', fd)
            .then(res => {
              const { statusCode, msg } = res.data
              this.fullscreenLoading = false // 提交时完成后取消加载中蒙版
              if (statusCode === '200') {
              // 轻提示框
                this.$toast.success(msg)
                // 发送成功后，跳转到登录页
                this.$router.push('/shWorkBench')
              } else {
              // 否则轻提示登录信息有误
                this.$toast.fail(msg)
              }
            })
            .catch(eror => {
              this.fullscreenLoading = false
              this.$toast.fail('接口请求异常，请联系管理员：' + eror)
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.billForm {
  .title {
    margin-top: 2rem;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .form-row {
    margin-top: 0.8rem;
  }

  .inline-input {
    width: 100%;
  }

  .submitButton {
    margin-top: 1rem;
  }
}

</style>
