<template>
  <div class="my">
    <my-header>我的</my-header>

    <!-- 用户信息 -->
    <div class="titel"></div>
    <div class="myInfo">
      <my-nav name="管理处" :desc="deptName"></my-nav>
      <my-nav name="所属单位" :desc="custName"></my-nav>
      <my-nav name="联系人" :desc="name"></my-nav>
      <my-nav name="电话" :desc="phone"></my-nav>
      <my-nav name="地址" :desc="address"></my-nav>
      <my-nav name="微信名" :desc="nickname"></my-nav>
    </div>
    <!-- <div class="theOther" v-if="this.custType != null"> -->
    <div class="theOther">

      <my-nav name="注销" id="loginOut" @click="loginOut"></my-nav>
    </div>
    <div id="welcome"></div>

    <!-- 底部 -->
    <div class="footer">
      <ul>
        <li>
          <div @click="$router.push({ name: 'ShHome' })">
            <i class="el-icon-s-home big"></i>
            <p>首页</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'ShTdList' })">
            <i class="el-icon-s-order"></i>
            <p>待办</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'ShWorkBench' })">
            <i class="el-icon-s-platform"></i>
            <p>工作台</p>
          </div>
        </li>
        <li>
          <div>
            <i class="el-icon-s-custom current"></i>
            <p>我的</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      custType: '',
      deptId: '',
      deptCode: '',
      deptName: '',
      custName: '',
      name: '',
      phone: '',
      address: '',
      nickname: ''
    }
  },
  created () {
    this.custType = localStorage.getItem('custType')
    this.deptId = localStorage.getItem('pk_dept')
    this.deptCode = localStorage.getItem('deptCode')
    this.deptName = localStorage.getItem('deptName')
    this.custName = localStorage.getItem('custName')
    this.name = localStorage.getItem('name')
    this.phone = localStorage.getItem('phone')
    this.address = localStorage.getItem('address')
    this.nickname = localStorage.getItem('nickname')
  },
  methods: {
    // 注销
    async loginOut () {
      const pkCrm = localStorage.getItem('pk_crm_wechat_trussup')
      if (pkCrm) {
        this.$router.push('/logout')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.my {
  .theOther {
    margin-bottom: 20px;
  }
  #bn {
    border-bottom: none;
  }
  #welcome {
    margin-top: 40px;
  }
}
</style>
