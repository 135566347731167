<template>
  <div class="billForm">
    <my-header>信息变更详情</my-header>

    <!-- 表单内容 -->
    <el-form
      :model="billForm"
      :rules="rules"
      ref="billRef"
      label-width="100px"
      class="billForm"
    >

      <div class="titel">申请信息</div>
      <el-row>
        <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">单据号</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
            <el-form-item class="imright">
              <span>{{billForm.billCode}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">单据日期</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
            <el-form-item class="imright">
              <span>{{billForm.applyDate}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">管理处</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
           <el-form-item class="imright">
              <span>{{billForm.deptName}}</span>
            </el-form-item>
        </el-col>
      </el-row>

      <el-row>
         <el-col :span="9">
            <el-form-item>
               <div class="bar"></div>
               <h3 style="margin-right:1rem">变更描述</h3>
            </el-form-item>
         </el-col>

         <el-col :span="15">
           <el-form-item class="imright">
              <span>{{billForm.changeInfo}}</span>
            </el-form-item>
        </el-col>
      </el-row>

    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      billId: '',
      busiType: '',
      dialogVisible: false,
      fullscreenLoading: false, // 加载中图层蒙版显示
      billForm: {
        deptName: '',
        billId: '',
        crmBusiType: '',
        changeInfo: '',
        satisfaction: 0,
        imageUrl: '',
        pk_crm_wechat_trussup: ''

      },
      rules: { // 校验规则

      }
    }
  },
  created () {
    // this.$route当前路由的相关信息(path/query/params)
    const { busiType, billId } = this.$route.params
    if (billId) {
      localStorage.setItem('billId', billId)
      localStorage.setItem('busiType', busiType)
      this.billId = billId
      this.busiType = busiType
    } else {
      this.billId = localStorage.getItem('billId')
      this.busiType = localStorage.getItem('busiType')
    }
    this.getOrderDetail(this.busiType, this.billId)
  },
  methods: {
    async getOrderDetail (busiType, billId) {
      // 从本地获取用户 id 和 token
      // 发送获取个人信息的请求时，需要携带上token令牌
      const pkCrm = localStorage.getItem('pk_crm_wechat_trussup')
      // const res = await this.$axios.get(`/ApproveQryDetailService?busiType=${busiType}&billId=${billId}&userId=${userId}`)
      await this.$axios.get('/AppCrmQryDetailService', {
        params: {
          busiType: busiType,
          billId: billId,
          pk_crm_wechat_trussup: pkCrm
        }
      }
      ).then((res) => {
        const { statusCode, data } = res.data
        if (statusCode === '200') {
          this.billForm = data.infochange
        }
      })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
// 上传区域大框框
.front,
.back {
  width: 100%;
  height: 158px;
  margin-top: 50px;
  text-align: center;
  border: 1px solid #dce4ff;
  border-radius: 5px;
}
</style>
