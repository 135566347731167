<template>
  <div>
    <my-header>附件查看</my-header>
    <!--图片查看插件-->
    <div class='d-pic'>
      <viewer :images="images">
      <img v-for="src in images" :src="src" :key="src" class="m-pic" />
    </viewer>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      images: []
    }
  },
  created () {
    const { url } = this.$route.params
    if (url) {
      this.images.push(url)
      localStorage.setItem('images', this.images)
    } else {
      this.images = localStorage.getItem('images')
    }
  }
}
</script>

<style lang="scss" scoped>

.d-pic{
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  width:400px;
  height:400px;
  margin:50px
}

.m-pic {
  max-width: 100%;
  max-height: 100%;
}

</style>
