<template>
  <!-- 导航横条组件封装 -->
  <div class="my-nav" @click="handleClick">
    <div class="left">{{ name }}</div>
    <div class="center">{{ desc }}</div>
    <div class="right">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
export default {
  // 子组件通过 props 传值
  props: {
    name: {
      type: String,
      required: true // 是否必须
    },
    desc: {
      type: String,
      required: ''
    }
  },
  methods: {
    // 子组件给父组件传值，通过 $emit
    handleClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.my-nav {
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  margin: 0 15px;

  .left {
    font-size: 16px;
    font-family: myFont2;
    color: #333333;
  }
  .center {
    flex: 1;
    text-align: right;
    font-size: 16px;
    font-family: myFont2;
    color: #333333;
  }
  .right {
    padding: 0 5px;
    font-size: 16px;
    color: #333333;
  }
}
</style>
