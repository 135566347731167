<template>
  <div class="billForm">
    <my-header>客户注册登记</my-header>

    <!-- 表单内容 -->
    <el-form :model="billForm" name="billForm" :rules="rules" ref="billRef" label-width="100px" class="billForm">

      <div class="titel">注册信息</div>
      <el-row class="form-row">
        <el-col :span="7">
          <el-form-item class="form-item-title">
            <div class="bar"></div>
            <h3><span style="color:red">*</span>管理处:</h3>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item prop="deptName">
            <el-input type="text" placeholder="扫描二维码获取" v-model="billForm.deptName" readonly>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
            <el-button @click.prevent="scanQRCode()" v-loading.fullscreen.lock="fullscreenLoading">扫一扫</el-button>
        </el-col>
      </el-row>
      <el-row class="form-row">
         <el-col :span="7">
            <el-form-item class="form-item-title">
               <div class="bar"></div>
               <h3><span style="color:red">*</span>客户名称:</h3>
            </el-form-item>
         </el-col>
      </el-row>
      <el-row class="form-row">
         <el-col :span="24" >
           <el-form-item prop="custName" style="margin-left:5px">
            <el-autocomplete
            v-model="billForm.custName"
            value-key="custName"
            name="custName"
            class="inline-input"
            placeholder="请输入后选择"
            :highlight-first-item="true"
            :trigger-on-focus="false"
            :fetch-suggestions="querySearch"
            @select="handleSelect"
            @blur="handleBlur"
            clearable
          />
            </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-row">
        <el-col :span="7">
          <el-form-item class="form-item-title">
              <div class="bar"></div>
              <h3><span style="color:red">*</span>联系人:</h3>
          </el-form-item>
        </el-col>
        <el-col :span="17" >
          <el-form-item prop="name">
          <el-input type="text" placeholder="请输入用户名" v-model="billForm.name">
          </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-row">
        <el-col :span="7">
          <el-form-item class="form-item-title">
              <div class="bar"></div>
              <h3><span style="color:red">*</span>电话:</h3>
          </el-form-item>
        </el-col>
        <el-col :span="17" >
          <el-form-item prop="phone">
          <el-input type="text" placeholder="请输入电话" v-model="billForm.phone">
          </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-row">
        <el-col :span="7">
          <el-form-item class="form-item-title">
              <div class="bar"></div>
              <h3><span style="color:red">*</span>联系地址:</h3>
          </el-form-item>
        </el-col>
        <el-col :span="17" >
          <el-form-item prop="address">
          <el-input type="text" placeholder="地址信息" v-model="billForm.address">
          </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 提交 -->
      <el-form-item class="submitButton">
        <el-button @click.prevent="submit()" class="subButton"
          v-loading.fullscreen.lock="fullscreenLoading">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import wx from 'weixin-jsapi' // 引入微信js-sdk

export default {
  data () {
    const validateCustmer = (rule, value, callback) => {
      if (!this.billForm.pk_customer) {
        callback(new Error('未选择客户名称！'))
      } else {
        callback()
      }
    }
    return {
      url: '',
      deptInfo: {},
      customers: [],
      dialogVisible: false,
      fullscreenLoading: false, // 加载中图层蒙版显示
      billForm: {
        custType: '',
        deptId: '',
        deptCode: '',
        deptName: '',
        custName: '',
        pk_customer: '',
        name: '',
        phone: '',
        address: ''
      },
      rules: { // 校验规则
        deptName: [
          { required: true, message: '通过扫一扫获取', trigger: 'blur' }
        ],
        custName: [
          { required: true, message: '必填', trigger: 'blur' },
          { required: true, message: '必填', trigger: 'blur', validator: validateCustmer }
        ],
        name: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '必填', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {

  },
  created () {
    const { url } = this.$route.params
    const ran = navigator.userAgent
    const isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
    const isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    if (isAndroid) {
      // Android需要使用当前页面的地址去生成签名
      var curUrl = encodeURIComponent(window.location.href.split('#')[0])
      this.url = curUrl
    }
    if (isIOS) {
      // iso需要使用上个页面url
      this.url = url
    }
  },
  methods: {
    getCustomer (deptCode) {
      this.$axios
        .get('/AppCrmCustALLQryService', {
          params: {
            deptCode: deptCode
          }
        }
        )
        .then(res => {
          const { code, msg, data } = res.data
          if (code === '1') {
            this.customers = data.customer
          } else {
            // 否则轻提示登录信息有误
            this.$toast.fail(msg)
          }
        })
        .catch(eror => {
          this.fullscreenLoading = false
          this.$toast.fail('接口请求异常，请联系管理员：' + eror)
        })
    },
    querySearch (queryString, cb) {
      var results = queryString ? this.customers.filter(this.createFilter(queryString)) : this.customers
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return (customers) => {
        return customers.custName.match(queryString)
      }
    },
    handleSelect (item) {
      this.billForm.custName = item.custName
      this.billForm.pk_customer = item.pk_customer
    },
    handleBlur () {
      // 判断输入文本query是否与选项option匹配
      const isMatched = this.customers.includes(this.billForm.custName)
      if (!isMatched || !this.billForm.pk_customer) {
        // 如果输入文本不匹配，则清空输入框的值
        this.billForm.custName = ''
        this.billForm.pk_customer = ''
      }
    },
    scanQRCode () {
      // 微信端判断
      if (!this.$tool.isWeChat()) {
        this.$toast.fail('请在微信端打开在尝试')
      }
      // url
      if (!this.url) {
        this.$toast.fail('获取URL失败')
      }
      this.$axios.post('/WXTicketService?url=' + this.url).then((res) => {
        const { statusCode, data } = res.data
        if (statusCode === '200') {
          if (data.code === -1) {
            this.$toast.fail('获取微信扫码授权失败')
          }
          this.wxConfig = data
          wx.config({
            // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            debug: false,
            // 必填，公众号的唯一标识
            appId: this.wxConfig.appId,
            // 必填，生成签名的时间戳
            timestamp: this.wxConfig.timestamp,
            // 必填，生成签名的随机串
            nonceStr: this.wxConfig.nonceStr,
            // 必填，签名，见附录1
            signature: this.wxConfig.signature,
            // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            jsApiList: ['scanQRCode']
          })
        }
      }).catch((error) => {
        console.log(error)
      })
      wx.error(res => {
        alert('出错了：' + res.errMsg)
      })
      wx.ready(() => {
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ['qrCode'], // 可以指定扫二维码还是一维码，默认二者都有
          success: res => {
            alert('扫描成功', res.resultStr)
            const resultStr = res.resultStr // 当needResult 为 1 时，扫码返回的结果
            if (!resultStr) {
              this.$toast.fail('扫码内容为空')
            }
            const array = resultStr.split('&')
            if (array.length === 0 || array.length !== 3) {
              this.$toast.fail('扫码结果无效')
            }
            this.deptInfo.deptId = array[0]
            this.deptInfo.deptCode = array[1]
            this.deptInfo.deptName = array[2]
            this.$axios
              .get('/AppCrmDeptQryService', {
                params: {
                  pk_dept: this.deptInfo.deptId
                }
              })
              .then(res => {
                const { statusCode, msg, data } = res.data
                if (statusCode === '200') {
                  this.deptInfo = data
                  this.billForm.deptId = data.pk_dept
                  this.billForm.deptCode = data.deptCode
                  this.billForm.deptName = data.deptName
                  // 初始化客户数据
                  this.getCustomer(data.deptCode)
                } else {
                  // 否则轻提示登录信息有误
                  this.$toast.fail(msg)
                  this.customers = []
                }
              })
              .catch(eror => {
                this.fullscreenLoading = false
                this.$toast.fail('接口请求异常，请联系管理员：' + eror)
              })
          }
        })
      })
    },
    submit () {
      this.$refs.billRef.validate(valid => {
        if (valid) {
          this.billForm.custType = localStorage.getItem('custType')
          this.billForm.openid = localStorage.getItem('openid')
          this.billForm.nickname = localStorage.getItem('nickname')
          this.billForm.sex = localStorage.getItem('sex')
          this.billForm.province = localStorage.getItem('province')
          this.billForm.city = localStorage.getItem('city')
          this.fullscreenLoading = true // 提交时显示加载中蒙版
          this.$axios
            .post('/CrmSigninService', this.billForm)
            .then(res => {
              const { statusCode, msg } = res.data
              this.fullscreenLoading = false // 提交时完成后取消加载中蒙版
              if (statusCode === '200') {
              // 轻提示框
                this.$toast.success(msg)
                // 发送成功后，跳转到登录页
                this.$router.push('/ShLogin')
              } else {
              // 否则轻提示登录信息有误
                this.$toast.fail(msg)
              }
            })
            .catch(eror => {
              this.fullscreenLoading = false
              this.$toast.fail('接口请求异常，请联系管理员：' + eror)
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.billForm {
  .title {
    margin-top: 2rem;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .form-row {
    margin-top: 0.8rem;
  }

  .inline-input {
    width: 100%;
  }

  .submitButton {
    margin-top: 1rem;
  }
}

</style>
