<template>
  <div class="billForm">
    <my-header>供应商注册登记</my-header>

    <!-- 表单内容 -->
    <el-form :model="billForm" name="billForm" :rules="rules" ref="billRef" label-width="100px" class="billForm">

      <div class="titel">注册信息</div>
      <el-row class="form-row">
         <el-col :span="7">
            <el-form-item class="form-item-title">
               <div class="bar"></div>
               <h3><span style="color:red">*</span>供应商名称:</h3>
            </el-form-item>
         </el-col>
      </el-row>
      <el-row class="form-row">
         <el-col :span="24" >
           <el-form-item prop="supName"  style="margin-left:5px">
            <el-autocomplete
            v-model="billForm.supName"
            value-key="supName"
            name="supName"
            class="inline-input"
            placeholder="请输入后选择"
            :highlight-first-item="true"
            :trigger-on-focus="false"
            :fetch-suggestions="querySearch"
            @select="handleSelect"
            @blur="handleBlur"
            clearable
          />
            </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-row">
        <el-col :span="7">
          <el-form-item class="form-item-title">
              <div class="bar"></div>
              <h3><span style="color:red">*</span>联系人:</h3>
          </el-form-item>
        </el-col>
        <el-col :span="17" >
          <el-form-item prop="name">
          <el-input type="text" placeholder="请输入用户名" v-model="billForm.name">
          </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-row">
        <el-col :span="7">
          <el-form-item class="form-item-title">
              <div class="bar"></div>
              <h3><span style="color:red">*</span>电话:</h3>
          </el-form-item>
        </el-col>
        <el-col :span="17" >
          <el-form-item prop="phone">
          <el-input type="text" placeholder="请输入电话" v-model="billForm.phone">
          </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="form-row">
        <el-col :span="7">
          <el-form-item class="form-item-title">
              <div class="bar"></div>
              <h3><span style="color:red">*</span>联系地址:</h3>
          </el-form-item>
        </el-col>
        <el-col :span="17" >
          <el-form-item prop="address">
          <el-input type="text" placeholder="地址信息" v-model="billForm.address">
          </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- 提交 -->
      <el-form-item class="submitButton">
        <el-button @click.prevent="submit()" class="subButton"
          v-loading.fullscreen.lock="fullscreenLoading">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    const validateSup = (rule, value, callback) => {
      if (!this.billForm.pk_supplier) {
        callback(new Error('未选择供应商名称！'))
      } else {
        callback()
      }
    }
    return {
      url: '',
      deptInfo: {},
      suppliers: [],
      dialogVisible: false,
      fullscreenLoading: false, // 加载中图层蒙版显示
      billForm: {
        custType: '',
        supName: '',
        pk_supplier: '',
        name: '',
        phone: '',
        address: ''
      },
      rules: { // 校验规则
        supName: [
          { required: true, message: '必填', trigger: 'blur' },
          { required: true, message: '必填', trigger: 'blur', validator: validateSup }
        ],
        name: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '必填', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {

  },
  created () {
    // 初始化供应商数据
    this.getSupplier()
  },
  methods: {
    getSupplier () {
      this.$axios
        .get('/AppCrmSupALLQryService')
        .then(res => {
          const { code, msg, data } = res.data
          if (code === '1') {
            this.suppliers = data.supplier
          } else {
            // 否则轻提示登录信息有误
            this.$toast.fail(msg)
          }
        })
        .catch(eror => {
          this.fullscreenLoading = false
          this.$toast.fail('接口请求异常，请联系管理员：' + eror)
        })
    },
    querySearch (queryString, cb) {
      var results = queryString ? this.suppliers.filter(this.createFilter(queryString)) : this.suppliers
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return (suppliers) => {
        return suppliers.supName.match(queryString)
      }
    },
    handleSelect (item) {
      this.billForm.supName = item.supName
      this.billForm.pk_supplier = item.pk_supplier
    },
    handleBlur () {
      // 判断输入文本query是否与选项option匹配
      const isMatched = this.suppliers.includes(this.billForm.supName)
      if (!isMatched || !this.billForm.pk_supplier) {
        // 如果输入文本不匹配，则清空输入框的值
        this.billForm.supName = ''
        this.billForm.pk_supplier = ''
      }
    },
    submit () {
      this.$refs.billRef.validate(valid => {
        if (valid) {
          this.billForm.custType = localStorage.getItem('custType')
          this.billForm.openid = localStorage.getItem('openid')
          this.billForm.nickname = localStorage.getItem('nickname')
          this.billForm.sex = localStorage.getItem('sex')
          this.billForm.province = localStorage.getItem('province')
          this.billForm.city = localStorage.getItem('city')
          this.fullscreenLoading = true // 提交时显示加载中蒙版
          this.$axios
            .post('/CrmSigninService', this.billForm)
            .then(res => {
              const { statusCode, msg } = res.data
              this.fullscreenLoading = false // 提交时完成后取消加载中蒙版
              if (statusCode === '200') {
              // 轻提示框
                this.$toast.success(msg)
                // 发送成功后，跳转到登录页
                this.$router.push('/ShLogin')
              } else {
              // 否则轻提示登录信息有误
                this.$toast.fail(msg)
              }
            })
            .catch(eror => {
              this.fullscreenLoading = false
              this.$toast.fail('接口请求异常，请联系管理员：' + eror)
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.billForm {
  .title {
    margin-top: 2rem;
    font-size: 1.2rem;
    font-weight: bold;
  }

  .form-row {
    margin-top: 0.8rem;
  }

  .inline-input {
    width: 100%;
  }

  .submitButton {
    margin-top: 1rem;
  }
}

</style>
