<template>
  <div class="workBench">
    <my-header>我的工作台</my-header>

    <van-collapse v-model="activeNames">
      <van-collapse-item
        :name="creRole.crmCrmRole.busiType"
        v-if="creRole.crmCrmRole != null"
        class="vci"
      >
        <div slot="title" style="font-size: 18px; font-weight: 700">
          {{ creRole.crmCrmRole.busiType }}
        </div>
        <van-grid :column-num="4">
          <van-grid-item
            v-for="item in creRole.crmCrmRole.roles"
            :key="item.rcode"
            :text="item.rname"
            @click="toCreateBill(item.rcode)"
          >
            <img
              :src="require('../../assets/apr_comsug.png')"
              v-if="item.rcode === 'comsug'"
            />
            <img
              :src="require('../../assets/apr_require.png')"
              v-if="item.rcode === 'require'"
            />
            <img
              :src="require('../../assets/apr_repair.png')"
              v-if="item.rcode === 'repair'"
            />
            <img
              :src="require('../../assets/apr_infochange.png')"
              v-if="item.rcode === 'infochange'"
            />
            <p class="p">{{ item.rname }}</p>
          </van-grid-item>
        </van-grid>
      </van-collapse-item>
    </van-collapse>

    <!-- 底部 -->
    <div class="footer">
      <ul>
        <li>
          <div @click="$router.push({ name: 'ShHome' })">
            <i class="el-icon-s-home big"></i>
            <p>首页</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'ShTdList' })">
            <i class="el-icon-s-order"></i>
            <p>待办</p>
          </div>
        </li>
        <li>
          <div>
            <i class="el-icon-s-platform current"></i>
            <p>工作台</p>
          </div>
        </li>
        <li>
          <div @click="$router.push({ name: 'ShMy' })">
            <i class="el-icon-s-custom"></i>
            <p>我的</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      creRole: {},
      activeNames: [],
      isLoading: false
    }
  },
  async created () {
    this.activeNames = ['CRM管理']
    // 从本地获取用户 id 和 token
    const pkcrm = localStorage.getItem('pk_crm_wechat_trussup')
    console.info(pkcrm)
    // 发送获取个人信息的请求时，需要携带上token令牌，需要放在请求头Authorization字段上
    await this.$axios.get('/AppCrmQryTotalService', {
      params: {
        pk_crm_wechat_trussup: pkcrm
      }
    }
    ).then((res) => {
      const { statusCode, data } = res.data
      if (statusCode === '200') {
        console.log('data:' + data)
        this.totalnum = data.totalnum
        if (data.aprRole) {
          this.aprRole = data.aprRole
          localStorage.setItem('aprRole', JSON.stringify(data.aprRole))
        }
        if (data.creRole) {
          this.creRole = data.creRole
          localStorage.setItem('creRole', JSON.stringify(data.creRole))
        }
      }
    })
      .catch((error) => {
        console.log(error)
      })
  },
  methods: {
    toCreateBill (busiType) {
      // 跳转到填报页面组件=Sh+业务类型+Form
      const pushName = 'Sh' + busiType[0].toUpperCase() + busiType.substr(1) + 'Form'
      this.$router.push({
        name: pushName,
        params: { busiType: busiType }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.workBench {
  height: 100%;
  // 单个订单
  .wait1,
  .completed1,
  .completed2 {
    border-radius: 7px;
    padding: 10px;
    box-shadow: 2px 2px 10px rgba(121, 118, 116, 0.4);
    margin: 20px 20px;
  }
}
.waitState {
  font-size: 0.3rem;
}

.vci {
  .title-class {
    font-size: 25px;
  }
}
.icon {
  font-size: 24px;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(rgb(93, 142, 218, 0.8)),
    to(rgb(93, 142, 218, 1))
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.p {
  font-size: 12px;
}
.vanbadge {
  text-align: center;
}

::v-deep .van-collapse-item__content {
  padding: 0rem 0rem;
  color: #969799;
  font-size: 0.376344rem;
  line-height: 0;
  background-color: #fff;
}
</style>
